import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import dayjs from "dayjs";
import authActions from "../../redux/auth/actions";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
import CetImage from "../../assets/images/courseRegistration/Cet.png";

export default function PreviewForm({ targetRef, courseId }) {
  const [CheckboxValue, setCheckBoxValue] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const { courseFormDetails, personalDetails } = useSelector(
    (state) => state.candidatesReducer
  );  

  const applicantid = useSelector(
    (state) => state.candidatesReducer.applicantid.cnd_getapplicantid_bycourseid
  );

  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  }, []);

  const { uploadDetails } = useSelector((state) => state.candidatesReducer);

  const status = courseFormDetails[0]?.status; 
  const isPaid = courseFormDetails[0]?.ispaid;


  const handleProcced = () => {
    if (CheckboxValue) {
      dispatch({ type:candidatesActions.COURSE_PREVIEW_UPDATION,
        payload :{ data : { courseid:courseid, candidateid:candidateid }, coursename:coursename, navigate:navigate}
       })
    } else {
      if (!CheckboxValue) {
        setError("Select the checkbox is required");
      }
    }
  };
  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckBoxValue(checked);
    setError("");
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_APPLICANTID,
        payload: {
          courseid: courseid ? courseid : courseId,
          candidateid: candidateid,
        },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if(uploadDetails){
      if (uploadDetails[2]?.document) {
        try {
          const base64Data = uploadDetails[2]?.document.split(',')[1] || uploadDetails[2]?.document;
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          return () => URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error decoding Base64 string:", error);
        }
      }
    }
  }, [uploadDetails[2]?.document]);

  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
      ref={targetRef}
    >
      {location.search === "?page=printapplication" &&
      (
       <Grid
          container
          p={3}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Grid item md={3}>
            <img
              style={{ height: "141px", width: "auto" }}
              src={CetImage}
              alt="image"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={7.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Government of Maharashtra
            </Typography>
            <Typography>
              State Common Entrance Test Cell, Mumbai
            </Typography>
            <Typography>
              {coursename}
            </Typography>
            <Typography>APPLICATION FORM</Typography>
          </Grid>
          <Grid
            item
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ height: "143px", width: "auto" }}
              src={GovernmentImage}
              alt="image"
            />
          </Grid>
        </Grid>)}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {location.pathname + location.search ===
          "/mhcet/course-registration?page=printapplication" &&
          applicantid !== "" && (
            <Typography
              variant="h4"
              sx={{
                textWrap: "wrap",
                fontSize: 28,
                fontWeight: "bold",
                color: "#3F41D1",
              }}
            >
              Application No - {applicantid}
            </Typography>
          )}
      </Box>
      <Grid container mt={1}>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Personal Details</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get("mhet_cnd_project")}/course-registration?page=personalinformation`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }                
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Candidate’s Full name
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.candidatename}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Father’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.fathersname}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Mother’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.mothername}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Marital Status
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.maritalstatusid) ===
                1
                  ?  "Married" :
                  parseInt(courseFormDetails[0]?.maritalstatusid) === 2 ?"Unmarried": "----"}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Spouse’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails?.length > 0 &&
                  (courseFormDetails[0]?.spousename || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.gender}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.dob &&
                  dayjs(personalDetails[0]?.dob).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Religion
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.religion}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Region
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.region}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Mother Tongue
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.mothertongue}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Annual Income
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.incomerange}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Nationality
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.nationality}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Permanent Address</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=address`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.paddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.paddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails?.length > 0 &&
                  (courseFormDetails[0]?.paddressline3 || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.pstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.pdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ptaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.pvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ppincode}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Telephone No
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.phoneno}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Address for Correspondence
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=address`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.caddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.caddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails?.length > 0 &&
                  (courseFormDetails[0]?.caddressline3 || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cdistrict === null ? 'Not Applicable' : courseFormDetails[0]?.cdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ctaluka === null ? 'Not Applicable' : courseFormDetails[0]?.ctaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvillage === null ? 'Not Applicable' : courseFormDetails[0]?.cvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={9}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cpincode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
        {(courseFormDetails[0]?.sscpassedfromindia || 
 courseFormDetails[0]?.sscschoolstate || 
 courseFormDetails[0]?.sscschooldistrict || 
 courseFormDetails[0]?.sscschooltaluka || 
 courseFormDetails[0]?.sscpassingyear || 
 courseFormDetails[0]?.sscboard || 
 courseFormDetails[0]?.sscschoolname || 
 courseFormDetails[0]?.ssctotalpercentage) && 
 <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Qualification Details</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=qualificationdetails`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>SSC Information</Typography>
            </Box>
          </Box>
          </>
}

          <Grid container md={12}>
            {courseFormDetails[0]?.sscpassedfromindia &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC Passed From India
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscpassedfromindia == 1 ? 'Yes' : courseFormDetails[0]?.sscpassedfromindia == 0 ? 'No' : "---"} 
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.sscschoolstate &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC School State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscschoolstate}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.sscschooldistrict &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC School District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscschooldistrict}
              </Typography>
            </Grid>
            </>
            }
            
            {courseFormDetails[0]?.sscschooltaluka &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC School Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscschooltaluka}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.sscpassingyear &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC/Equivalent Passing Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscpassingyear}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.sscboard &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC/Equivalent Board
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sscboard}
              </Typography>
            </Grid>
            </>
            }

          {courseFormDetails[0]?.sscschoolname &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC School Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                fontSize: 15,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}>
                {courseFormDetails[0]?.sscschoolname}
              </Typography>
            </Grid>
            </>
          }

          {courseFormDetails[0]?.ssctotalpercentage &&
          <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC/Equivalent Total Percentage
              </Typography>
            </Grid>

            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
              sx={{
                fontSize: 15,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}>
                {courseFormDetails[0]?.ssctotalpercentage}
              </Typography>
            </Grid>
            </>
          }
          </Grid>
          {(courseFormDetails[0]?.hsccollegestate || 
 courseFormDetails[0]?.hsccollegedistrict || 
 courseFormDetails[0]?.hsccollegetaluka || 
 courseFormDetails[0]?.hsccollegename || 
 courseFormDetails[0]?.hscmarktype || 
 courseFormDetails[0]?.hscmarksobtained || 
 courseFormDetails[0]?.hscmarksoutof || 
 courseFormDetails[0]?.hscpercentage || 
 courseFormDetails[0]?.hscenglishmarksobtained || 
 courseFormDetails[0]?.hscenglishmarksoutof || 
 courseFormDetails[0]?.hscenglishpercentage ||
 courseFormDetails[0]?.hscpassingyear ||
 courseFormDetails[0]?.hscboard) &&
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>HSC Information</Typography>
            </Box>
          </Box>
}
          <Grid container md={12}>

            {courseFormDetails[0]?.hsccollegestate &&
             <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC College State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hsccollegestate}
              </Typography>
            </Grid>
            </>
            }

          {courseFormDetails[0]?.hsccollegedistrict &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC College District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hsccollegedistrict}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.hsccollegetaluka &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC College Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hsccollegetaluka}
              </Typography>
            </Grid>
            </>
            }

{courseFormDetails[0]?.hscpassingyear &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC Passing Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hscpassingyear}
              </Typography>
            </Grid>
            </>
            }

{courseFormDetails[0]?.hscboard &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC Board
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hscboard}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.hsccollegename && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC College Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
              sx={{
                fontSize: 15,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}              >
              {courseFormDetails[0]?.hsccollegename}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.hscmarktype &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Marks Type
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.hscmarktype) === 1 ? 'CGPA' : parseInt(courseFormDetails[0]?.hscmarktype) === 2 ? 'Percentage' : '-----'}
              </Typography>
            </Grid>
            </>
            }
            {courseFormDetails[0]?.hscmarksobtained &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hscmarksobtained}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.hscmarksoutof && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.hscmarksoutof}
              </Typography>
            </Grid>
            </>
            }

            {courseFormDetails[0]?.hscpercentage &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
              >
                {courseFormDetails[0]?.hscpercentage}
              </Typography>
            </Grid>
            </>
            }


            {courseFormDetails[0]?.hscenglishmarksobtained && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC English Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
              sx={{
                fontSize: 15,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}              >
              {courseFormDetails[0]?.hscenglishmarksobtained}
              </Typography>
            </Grid>
            </>
            }


            {courseFormDetails[0]?.hscenglishmarksoutof &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC English Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
               sx={{
                fontSize: 15,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
               textAlign: "left",
              }}              >
                
                {courseFormDetails[0]?.hscenglishmarksoutof}
              </Typography>
            </Grid>
            </>
            }


            {courseFormDetails[0]?.hscenglishpercentage &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC English Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                 fontSize: 15,
                 textAlign: "left",
                 color: "black",
                 fontWeight: 500,
                 textAlign: "left",
                }}              >
                
                {courseFormDetails[0]?.hscenglishpercentage}

              </Typography>
            </Grid>
            </>
            }
          </Grid>

          {(courseFormDetails[0]?.eligibilityqualification || 
 courseFormDetails[0]?.graduationstatus || 
 courseFormDetails[0]?.graduationpassingyear || 
 courseFormDetails[0]?.graduationbranch || 
 courseFormDetails[0]?.graduationmarktype || 
 courseFormDetails[0]?.graduationmarksobtained || 
 courseFormDetails[0]?.graduationmarksoutof || 
 courseFormDetails[0]?.graduationpercentage || 
 courseFormDetails[0]?.awardedclass || 
 courseFormDetails[0]?.gradudationduration) &&
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Graduation Information</Typography>
            </Box>
          </Box>}
          <Grid container md={12}>

            {courseFormDetails[0]?.eligibilityqualification &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Eligiblity Qualification
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
             <Typography
              sx={{
              fontSize: 15,
              textAlign: "left",
              color: "black",
              fontWeight: 500,
              textAlign: "left",
              }}>
                {courseFormDetails[0]?.eligibilityqualification}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationstatus &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Status
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.graduationstatus == 1 ? "Appearing" : courseFormDetails[0]?.graduationstatus == 2 ? "Passed" : courseFormDetails[0]?.graduationstatus == 0 ? 'Not Applicable' : '---'}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationpassingyear && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Passing Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.graduationpassingyear}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationbranch &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Branch Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                                 <Typography
   sx={{
    fontSize: 15,
    textAlign: "left",
    color: "black",
    fontWeight: 500,
    textAlign: "left",
  }}              >
                
                {courseFormDetails[0]?.graduationbranch}

              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationmarktype && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Marks Type
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.graduationmarktype) == 1 ? 'Percentage' : parseInt(courseFormDetails[0]?.graduationmarktype) === 2 ? 'CGPA' : '-----' }
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationmarksobtained &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.graduationmarksobtained}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.graduationmarksoutof &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.graduationmarksoutof}
              </Typography>
            </Grid>
            </>}



            {courseFormDetails[0]?.graduationpercentage &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.graduationpercentage}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.awardedclass &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Awarded Class Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
           <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.awardedclass}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.gradudationduration &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Graduation Duration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.gradudationduration}
              </Typography>
            </Grid>
            </>}
          </Grid>

          {/* <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Post Graduation Information</Typography>
            </Box>
          </Box> */}
          {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Document For Type A
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Document Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                CET Exam Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Whether Pass Institution Situated MH?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
             <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                PF Graduation Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Internship Training Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                MH Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Re Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
             <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Already Admitted
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                College Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                University Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
          {/* <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>First Year Marks</Typography>
            </Box>
          </Box> */}
          {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
          {/* <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Second Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Third Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                TY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                TY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Fourth Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FourY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                FourY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Aggregate Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Aggregate Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Aggregate Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Aggregate Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Domicile and Category Deatils</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=domicileandcategorydetails`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            {(courseFormDetails[0]?.issports || 
 courseFormDetails[0]?.sportsparticipation || 
 courseFormDetails[0]?.sportsparticipationyear || 
 courseFormDetails[0]?.sportsrank || 
 courseFormDetails[0]?.sports || 
 courseFormDetails[0]?.sportsbody || 
 courseFormDetails[0]?.isphysicaleducation || 
 courseFormDetails[0]?.isteachingexperience || 
 courseFormDetails[0]?.isappliedforelct || 
 courseFormDetails[0]?.isappliedforksu || 
 courseFormDetails[0]?.isappliedforspecialeducation || 
 courseFormDetails[0]?.specialeducationmethods) && <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem",
              width:"100%"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Sports information</Typography>
            </Box>
          </Box>
          </>}
          <Grid container md={12}>

            {courseFormDetails[0]?.issports &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Sports    
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.issports) === 1 ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.sportsparticipation &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Sports Participation Level
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sportsparticipation}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.sportsparticipationyear &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Sports Participation Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sportsparticipationyear}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.sportsrank &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Sports Rank
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sportsrank}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.sports && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
               Sports Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sports}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.sportsbody &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Sports Body
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.sportsbody}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isphysicaleducation && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Studied Physical Education
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isphysicaleducation) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isphysicaleducation) == 0 ? 'No' : '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isteachingexperience && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Teaching Experience
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left",color: "black"}}
              >
                {parseInt(courseFormDetails[0]?.isteachingexperience) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isteachingexperience) == 0 ? 'No': '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isappliedforelct && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Applied For ELCT
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "black" }}
              >
                 {parseInt(courseFormDetails[0]?.isappliedforelct) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isappliedforelct) == 0 ?  'No' :'----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isappliedforksu && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Applied For KSU
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "black" }}
              >
                {parseInt(courseFormDetails[0]?.isappliedforksu) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isappliedforksu) == 0 ? 'No' :'----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isappliedforspecialeducation &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Is Applied For Special Education
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "black" }}
              >
                {parseInt(courseFormDetails[0]?.isappliedforspecialeducation) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isappliedforspecialeducation) == 0 ? 'No' : '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.specialeducationmethods && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Special Education Methods
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "black" }}
              >
                {parseInt(courseFormDetails[0]?.specialeducationmethods) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.specialeducationmethods) == 0 ? 'No': '----'}
              </Typography>
            </Grid>
            </>}
          </Grid>
          {(courseFormDetails[0]?.isdisability || 
 courseFormDetails[0]?.pwdsubtype || 
 courseFormDetails[0]?.isextratime || 
 courseFormDetails[0]?.isscribe) && <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem",
              width:"100%"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Disability Information</Typography>
            </Box>
          </Box>
          </>}
          <Grid container md={12}>

            {courseFormDetails[0]?.isdisability && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are You Person With Disability
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isdisability) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isdisability) == 0 ? 'No' : '---'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.pwdtype && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Type Of Disability
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.pwdtype}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.pwdsubtype && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                PWD Sub Type
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.pwdsubtype}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.isextratime && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Require Extra Time
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isextratime) == 1 ? 'Yes' :parseInt(courseFormDetails[0]?.isextratime) == 0  ?'No' : '----'}
              </Typography>
            </Grid>
            </>}
            

            {courseFormDetails[0]?.isscribe && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Require Scribe
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isscribe) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isscribe) == 0 ? 'No' : '----'}
              </Typography>
            </Grid>
            </>}
          </Grid>
          {(courseFormDetails[0]?.isorphan || courseFormDetails[0]?.orphantype) &&
          <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem",
              width:"100%"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Orphan Information</Typography>
            </Box>
          </Box>
          </>}
          <Grid container md={12}>

            {courseFormDetails[0]?.isorphan &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are You Orphan
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isorphan) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isorphan) == 0 ? 'No': '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.orphantype &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Orphan Type
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.orphantype}
              </Typography>
            </Grid>
            </>}
          </Grid>   
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem",
              width:"100%"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Category information</Typography>
            </Box>
          </Box>
          <Grid container md={12}>

            {courseFormDetails[0]?.ismhcandidate &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are You Domiciled In The State Of Maharastra
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.ismhcandidate) == 1 ? 'Yes' : parseInt(courseFormDetails[0]?.ismhcandidate) == 0 ?'No' : '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.category && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Category
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.category}
              </Typography>
            </Grid>
            </>}


            {courseFormDetails[0]?.castename && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.castename}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.caste &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste To Which You Belong
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.caste}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.appliedforews &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
               Do You Want To Apply For EWS
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.appliedforews) === 1 ? 'Yes' : parseInt(courseFormDetails[0]?.appliedforews) == 0 ? 'No': '----'}
              </Typography>
            </Grid>
            </>}

            {(courseFormDetails[0]?.ewscertificatestatus !== null &&
  (courseFormDetails[0]?.ewscertificatestatus === 0 ||
  courseFormDetails[0]?.ewscertificatestatus === 1 ||
  courseFormDetails[0]?.ewscertificatestatus === 2)) &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                EWS Certificate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.ewscertificatestatus) === 1 ? "Available" : parseInt(courseFormDetails[0]?.ewscertificatestatus) === 2 ? "Not Available" : parseInt(courseFormDetails[0]?.ewscertificatestatus) === 0 ? "Applied But Not Received" :'----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.ewsapplicationno && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                EWS Certificate Application Number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ewsapplicationno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.ewsapplicationdate && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                EWS Certificate Application Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ewsapplicationdate}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.ewsdistrict && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                EWS Certificate Issuing District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ewsdistrict}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.ewstaluka && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
               EWS Certificate Issuing Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.ewstaluka}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.castecertificatestatus && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do You Possess Caste Certificate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.castecertificatestatus) === 1 ? 'Available' : parseInt(courseFormDetails[0]?.castecertificatestatus) === 2 ? 'Applied But Not Received' : parseInt(courseFormDetails[0]?.castecertificatestatus) === 3 ? 'Not Applied' : '-----' }
              </Typography>
            </Grid>
            </>}

            {((courseFormDetails[0]?.castevaliditystatus !== null && courseFormDetails[0]?.castevaliditystatus !== undefined && courseFormDetails[0]?.castevaliditystatus !== "") || (courseFormDetails[0]?.castevaliditystatus === 0)) && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Do You Possess Caste/Tribe Validity Certificate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.castevaliditystatus) === 1 ? "Available" : parseInt(courseFormDetails[0]?.castevaliditystatus) === 2 ? "Applied But Not Received" : parseInt(courseFormDetails[0]?.castevaliditystatus) === 0 ? 'Not Applied' : '-----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.castevaliditycertificateno && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Caste Validity Certificate No
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.castevaliditycertificateno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.cvcapplicationno && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste/Tribe Validity Certificate Application Number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvcapplicationno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.cvcapplicationdate && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Caste/Tribe Validity Certificate Application Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvcapplicationdate}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.cvcauthority && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste/Tribe Validity Certificate Issuing Authority Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvcauthority}
              </Typography>
            </Grid>
            </>}

              
             {courseFormDetails[0]?.cvcdistrict && 
             <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste/Tribe Validity Certificate Issuing District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvcdistrict}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.cvcname &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Candidate Name As Per Caste Certificate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.cvcname}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.castecertificateno && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Caste Certificate/Receipt No
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.castecertificateno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.castecertificatereceiptno &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Caste Certificate Receipt No
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.castecertificatereceiptno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.castecertificateappdate &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Caste Certificate Application Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.castecertificateappdate}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.nclstatus &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                 Do You Have Non Creamy Layer Certificate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.nclstatus) === 1 ? 'Available' : parseInt(courseFormDetails[0]?.nclstatus) === 2 ? 'Applied But Not Received' : parseInt(courseFormDetails[0]?.nclstatus) === 3 ? 'Not Applied' : '-----'} 
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.nclapplicationno &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Non Creamy Layer Certificate Application Number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                     <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.nclapplicationno}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.nclapplicationdate &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Non Creamy Layer Certificate Application Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.nclapplicationdate}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.nclauthority &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                NCL Authority
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
                      <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.nclauthority}
              </Typography>
            </Grid>
            </>}

          </Grid>
          {(courseFormDetails[0]?.isminority || 
 courseFormDetails[0]?.linguisticminority || 
 courseFormDetails[0]?.religiousminority) 
            && <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop:".1rem",
              width:"100%"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Minority Information</Typography>
            </Box>
          </Box>
          </>}
          <Grid container md={12}>
            {courseFormDetails[0]?.isminority && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do You Belong To Minority Category
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFormDetails[0]?.isminority) === 1 ? 'Yes' : parseInt(courseFormDetails[0]?.isminority) === 0 ?  'No' : '----'}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.linguisticminority && 
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Linguistic Minority
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.linguisticminority}
              </Typography>
            </Grid>
            </>}

            {courseFormDetails[0]?.religiousminority &&
            <>
            <Grid
              item
              md={3}
              xs={3}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Religious Minority
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={3}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.religiousminority}
              </Typography>
            </Grid>
            </>}
          </Grid>
          </Grid>
{(courseFormDetails[0]?.subjectgroup || 
 courseFormDetails[0]?.examstate || 
 courseFormDetails[0]?.language || 
 courseFormDetails[0]?.preference1district || 
 courseFormDetails[0]?.preference2district || 
 courseFormDetails[0]?.preference3district || 
 courseFormDetails[0]?.preference4district) &&
 <>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Exam Centre Selection Details
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=examinationdetails`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          </>}
          {courseFormDetails[0]?.subjectgroup && 
            <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Subject Group for Exam
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.subjectgroup}
              </Typography>
            </Grid>
          </Grid>
          </>
            }

          {courseFormDetails[0]?.examstate && 
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Centre State
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.examstate}
              </Typography>
            </Grid>
          </Grid>
          </>
}

          {courseFormDetails[0]?.language && 
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Language / Medium of the Question Paper
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              {courseFormDetails[0]?.language?.map((val, i) => (
                <Typography
                  key={i}
                  sx={{
                    fontSize: 15,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {i + 1}.{val}
                </Typography>
              ))}
            </Grid>
          </Grid>
          </>}

          {courseFormDetails[0]?.preference1district &&
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 1
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.preference1district}
              </Typography>
            </Grid>
          </Grid>
          </>}


          {courseFormDetails[0]?.preference2district && 
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 2
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.preference2district}
              </Typography>
            </Grid>
          </Grid>
          </>}


          {courseFormDetails[0]?.preference3district && 
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 3
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.preference3district}
              </Typography>
            </Grid>
          </Grid>
          </>}


          {courseFormDetails[0]?.preference4district &&
          <>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 4
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails?.length > 0 &&
                  (courseFormDetails[0]?.preference4district ||
                    "-----")}
              </Typography>
            </Grid>
          </Grid>
          </>}
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Photograph and Signature
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photograph
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              p={1}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails[0]?.document}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "53px", width: "auto" }}
                src={uploadDetails[1]?.document}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container
            // md={12}
            // xs={12}
            p={1}
            sx={{
              height: "48px",
              width:"100%",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    { state: { courseid: courseid,coursename: coursename, status: status >= 5 ? 1 : 0 } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={2}
              xs={2}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "#3F41D1",
                  fontWeight: 500,
                }}
              >
                S.No
              </Typography>
            </Grid>
            <Grid
              item
              md={10}
              p={1}
              xs={10}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              {/* <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "#3F41D1",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography> */}
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={2}
              xs={2}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              p={1}
              xs={4}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {uploadDetails[0]?.documentname}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={6}
              sx={{ border: "1px solid #F7F7F7",display:"flex",justifyContent:"center"}}
            >
              <Box
                component="a"
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "#3F41D1",
                  fontWeight: 500,
                  textAlign: "center",
                  cursor:"pointer",
                  ':hover':{
                    textDecoration:"underline"
                  }
                }}
                target="_blank"
                href={pdfUrl}
              >
                View
              </Box>
            </Grid>
            {location.pathname + location.search ===
              "/mhcet/course-registration?page=preview" && (
              <>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    Note :
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "red",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    *Candidate Shall Carry Disability Certificate at the time of
                    Examination.( For All PWD Candidate)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={CheckboxValue || isPaid === '1'}
                      sx={{ margin: 0, padding: 0 }}
                    />
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "red",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      I have read all important Instructions.
                    </Typography>
                  </Box>
                </Grid>
                {error && (
                  <small style={{ marginTop: ".5rem" }} className="error">
                    {error}
                  </small>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {location.pathname + location.search ===
        "/mhcet/course-registration?page=preview" && (
        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <MauvelousPrimaryButton
            title={buttonLoader ? "loader" : "Continue to payment"}
            handleClick={handleProcced}
            disabled={!CheckboxValue || isPaid === '1'}
          />
        </Grid>
      )}
    </Grid>
  );
}
