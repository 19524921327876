import { Box, Card, Grid, Typography, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import "./courseSelection.css";
import candidatesActions from "../../redux/candidates/actions";
import { cssProperties } from "../../utils/commonCssProperties";
import { FiDownload } from "react-icons/fi";

export default function CourseList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { courseList } = useSelector((state) => state.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const transformedCourses = React.useMemo(
    () => transformCourses(courseList?.filtered),
    [courseList?.filtered]
  );

  React.useMemo(
    () => transformCourses(courseList?.filtered),
    [transformedCourses]
  );

  useEffect(() => {
    if (candidateid) dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST });
  }, [candidateid]);
  useEffect (()=>{
    dispatch({
      type: candidatesActions.SET_COURSE_FORM_DETAILS,
      payload: [],
    });
    dispatch({
      type: candidatesActions.SET_FULL_CANDIDATE_DETAILS,
      payload: [],
    });
    dispatch({
      type: candidatesActions.SET_COURSE_ACTIVE_TAB,
      payload:
        [],
    });
  },[])

  // function transformCourses(courses) {
  //   const result = [];
  //   const categoryMap = new Map();

  //   const activeCourses = courses?.filter(course => course.isactive === "1");

  //   console.log(activeCourses, "courseList6666666s");

  //   activeCourses.forEach((course) => {
  //     const {
  //       courseid,
  //       coursecategory,
  //       coursename,
  //       regstartdate,
  //       regenddate,
  //       corcandidateid,
  //       isactive,
  //       ispaid,
  //       brochurelocation,
  //       drivename,
  //     } = course;

  //     if (!categoryMap.has(coursecategory)) {
  //       categoryMap.set(coursecategory, []);
  //     }

  //     let registerStatus = "";
  //     if (corcandidateid) {
  //       registerStatus =
  //         ispaid === "1" ? "Already registered" : "Payment to be done";
  //     } else if (isactive === "1") {
  //       if (new Date(regstartdate) > new Date()) {
  //         registerStatus = "Registration yet to start";
  //       }
  //       if (new Date(regenddate) < new Date()) {
  //         registerStatus = "Registration closed";
  //       }
  //       if (
  //         new Date(regstartdate) <= new Date() &&
  //         new Date(regenddate) >= new Date()
  //       ) {
  //         registerStatus = "Register now";
  //       }
  //     } else {
  //       registerStatus = "Registration closed";
  //     }

  //     let lastDate = regenddate
  //       ? new Date(regenddate).toLocaleDateString("en-GB")
  //       : "";

  //     let dateRange =
  //       regstartdate && regenddate
  //         ? `${new Date(regstartdate).toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "numeric",
  //           })}-${new Date(regenddate).toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "numeric",
  //           })}`
  //         : "";

  //     categoryMap.get(coursecategory).push({
  //       name: coursename,
  //       date: dateRange,
  //       register: registerStatus,
  //       brochurelocation: brochurelocation,
  //       courseid: courseid,
  //       lastDate: lastDate,
  //       drivename: drivename,
  //       isactive: isactive,
  //     });
  //   });

  //   categoryMap.forEach((courses, courseCategory) => {
  //     result.push({
  //       courseCategory: courseCategory,
  //       course: courses,
  //     });
  //   });

  //   return result;
  // }
  //   function transformCourses(courses) {
  //     const result = [];
  //     const categoryMap = new Map();

  //     const activeCourses = courses?.filter(course => course?.isactive === "1");

  //     console.log(activeCourses, "courseList6666666s");

  //     activeCourses.forEach((course) => {
  //       const {
  //         courseid,
  //         coursecategory,
  //         coursename,
  //         regstartdate,
  //         regenddate,
  //         corcandidateid,
  //         isactive,
  //         ispaid,
  //         brochurelocation,
  //         drivename,
  //         candidatestatus,
  //       } = course;

  //       if (!categoryMap.has(coursecategory)) {
  //         categoryMap.set(coursecategory, []);
  //       }

  //       let registerStatus = "";

  //       const startDate = new Date(`${regstartdate.split('/').reverse().join('-')}T00:01`);
  //       const endDate = new Date(`${regenddate.split('/').reverse().join('-')}T23:59`);

  //       const currentDate = new Date();
  //       // courseid === 42 && console.log(startDate,endDate)
  //       // courseid === 42 && console.log(currentDate < startDate)
  //       // courseid === 42 && console.log(currentDate > endDate)
  //       // courseid === 42 && console.log(currentDate >= startDate,currentDate <= endDate,currentDate >= startDate && currentDate <= endDate)

  //       courseid === 42 && console.log(corcandidateid,isactive === "1")
  //       if (corcandidateid) {
  //         registerStatus =
  //           ispaid === "1" ? "Already registered" : "Payment to be done";
  //       } else if (isactive === "1") {
  //         if (currentDate < startDate) {
  //           registerStatus = "Registration yet to start";
  //         }
  //         if (currentDate > endDate) {
  //           registerStatus = "Registration closed";
  //         }
  //         if (currentDate >= startDate && currentDate <= endDate) {
  //           registerStatus = "Register now";
  //         }
  //       } else {
  //         registerStatus = "Registration closed";
  //       }

  //       let lastDate = regenddate
  //         ? new Date(regenddate).toLocaleDateString("en-GB")
  //         : "";

  //       let dateRange =
  //         regstartdate && regenddate
  //           ? `${new Date(regstartdate).toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "numeric",
  //           })}-${new Date(regenddate).toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "numeric",
  //           })}`
  //           : "";

  //       categoryMap.get(coursecategory).push({
  //         name: coursename,
  //         candidatestatus: candidatestatus,
  //         date: dateRange,
  //         register: registerStatus,
  //         brochurelocation: brochurelocation,
  //         courseid: courseid,
  //         lastDate: lastDate,
  //         drivename: drivename,
  //         isactive: isactive,
  //       });
  //     });

  //     categoryMap.forEach((courses, courseCategory) => {
  //       result.push({
  //         courseCategory: courseCategory,
  //         course: courses,
  //       });
  //     });
  // console.log(result,'results');
  //     return result;
  //   }

  return (
    <Box
      sx={{
        margin: "1rem auto",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      {transformedCourses?.map((val, listInd) => (
        <Box key={listInd} sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "rgba(80, 78, 80, 1)",
              fontSize: "20px",
              lineHeight: "24px",
              mb: 2,
              background: cssProperties?.backgroundcolor?.primary3,
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {val?.drive}
          </Typography>

          {val?.categories?.map((category, i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Box
                sx={{
                  background: cssProperties?.backgroundcolor?.primary,
                  height: "32px",
                  width: "280px",
                  borderRadius: "0px 100px 100px 0px",
                  color: cssProperties?.color?.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  padding: "0 8px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                <Typography>{category?.courseCategory}</Typography>
              </Box>
              <Course course={category?.courses} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

// const Course = ({ course }) => {
//   const navigate = useNavigate();

//   const handleDownload = (brochurelocation, name) => {
//     if (brochurelocation) {
//       const linkSource = brochurelocation;
//       const downloadLink = document.createElement("a");
//       const fileName = `${name}.pdf`;
//       downloadLink.href = linkSource;
//       downloadLink.download = fileName;
//       downloadLink.click();
//     }
//   };

//   return (
//     <Grid container spacing={2} pt={2}>
//       {course?.map((c, index) => (
//         <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
//           <Card
//             sx={{
//               width: "Fill 409.33px",
//               height: "150px",
//               padding: "24px",
//               borderRadius: "10px",
//               border: "1px solid #EAEAEA",
//               boxShadow: "2px 2px 12px 0px #0000001F",
//             }}
//           >
//             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//               <Box sx={{ fontSize: "20px", fontWeight: "bold",color:"rgba(80, 78, 80, 1)" }}>{c?.name}
//               </Box>
//               {c?.brochurelocation && (
//                 <Tooltip title="Download Brochure " placement="top">
//                   <Box
//                     sx={{ cursor: "pointer" }}
//                     onClick={() => handleDownload(c?.brochurelocation, c?.name)}
//                   >
//                     <FiDownload style={{ width: "24px", height: "24px" }} />
//                   </Box>
//                 </Tooltip>
//               )}
//             </Box>

//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             ></Box>

//             {new Date(`${c.lastDate.split('/').reverse().join('-')}T23:59`) <= new Date()  ? (
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   marginTop: 6,
//                 }}
//               >
//                 <Box
//                   className="course_list_register_now"
//                   onClick={() =>
//                     navigate(
//                       `/${Cookies.get("mhet_cnd_project")}/pre-request`,
//                       { state: { courseid: c.courseid } }
//                     )
//                   }
//                 >
//                   Registration Live
//                 </Box>
//                 <Typography variant="subtitle2" style={{fontSize:"14px",fontWeight:"bold",color:" rgba(80, 78, 80, 1)"}}>
//                   Last Date - {c?.lastDate}
//                 </Typography>
//               </Box>
//             ) : c?.register === "Registration yet to start" ? (
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box className="course_list_register_yet_to_start">
//                   Registration yet to start
//                 </Box>
//                 {c.brochurelocation && (
//                   <Box sx={{ cursor: "pointer" }}>
//                     <GrDocumentDownload />
//                   </Box>
//                 )}
//               </Box>
//             ) : c?.register === "Already registered" ? (
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box className="course_list_registration_closed">
//                   Already registered
//                 </Box>
//                 {c.brochurelocation && (
//                   <Box sx={{ cursor: "pointer" }}>
//                     <GrDocumentDownload />
//                   </Box>
//                 )}
//               </Box>
//             ) : c?.register === "Payment to be done" ? (
//               <Box
//                 sx={{
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box
//                   className="course_list_payment_to_be_done"
//                   sx={{ cursor: "pointer" }}
//                   onClick={() =>
//                     navigate(
//                       `/${Cookies.get(
//                         "mhet_cnd_project"
//                       )}/course-registration?page=personalinformation`,
//                       { state: { courseid: c.courseid } }
//                     )
//                   }
//                 >
//                   Status{" "}
//                   <Typography variant="subtitle2" className="course_list_payment_to_be_done">
//                     (Payment to be done){" "}
//                   </Typography>
//                 </Box>

//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     marginTop: 2,
//                   }}
//                 >
//                   <Box
//                     className="course_list_register_now"
//                     onClick={() =>
//                       navigate(
//                         `/${Cookies.get("mhet_cnd_project")}/pre-request`,
//                         { state: { courseid: c.courseid } }
//                       )
//                     }
//                   >
//                     Registration Live
//                   </Box>
//                   <Typography variant="subtitle2">
//                     Last Date - {c?.lastDate}
//                   </Typography>
//                 </Box>
//               </Box>
//             ) : c?.register === "Registration closed" ? (
//               <Box
//                 sx={{
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   marginTop: 2,
//                 }}
//               >
//                 <Box
//                   className="course_list_payment_to_be_done"
//                   sx={{ cursor: "pointer" }}
//                   onClick={() =>
//                     navigate(
//                       `/${Cookies.get(
//                         "mhet_cnd_project"
//                       )}/course-registration?page=personalinformation`,
//                       { state: { courseid: c.courseid } }
//                     )
//                   }
//                 >
//                   Status{" "}
//                   <Typography variant="subtitle2" className="course_list_payment_to_be_done">
//                     (Payment to be done){" "}
//                   </Typography>
//                 </Box>

//                 <Box className="course_list_registration_closed">
//                   Registration Closed
//                 </Box>
//               </Box>
//             ) : null}
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };
const Course = ({ course }) => {
  const navigate = useNavigate();
  const { courseList } = useSelector((state) => state.candidatesReducer);

  const activeCourses = course.filter((c) => c.isactive === "1");

  console.log(activeCourses, "courseList--active--filter");

  const handleDownload = (brochurelocation, name) => {
    if (brochurelocation) {
      const linkSource = brochurelocation;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };
  console.log(course, "courseList");

  return (
    <Grid container spacing={2} pt={2}>
      {activeCourses?.map((c, index) => (
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          key={index}
          sx={{ minWidth: "350px" }}
        >
          <Card
            sx={{
              // width: "fill 409.33px",
              // minWidth: "409.33px",
              height: "180px",
              padding: "24px",
              borderRadius: "10px",
              border: "1px solid rgba(234, 234, 234, 1)",
              boxShadow: "2px 2px 12px 0px rgba(0, 0, 0, 0.12)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
             
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", gap:2, }}>
                <Tooltip title={c?.name} followCursor>
                  <Box
                    sx={{
                     width:'80%',
                    
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "rgba(80, 78, 80, 1)",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, 
                        wordWrap: 'break-word',
                      }}
                    >
                      {c?.name}
                    </Typography>
                  </Box>
                </Tooltip>
                {c?.brochurelocation && (
                  <Tooltip title="Download Brochure " placement="top">
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(c?.brochurelocation, c?.name)
                      }
                    >
                      <FiDownload style={{ width: "24px", height: "24px" }} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
              <Box
                className="course_list_payment_to_be_done"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=personalinformation`,
                    {
                      state: {
                        courseid: c.courseid,
                        coursename: c?.name,
                        candidatestatus: c?.candidatestatus,
                      },
                    }
                  )
                }
              >
                <Typography variant="subtitle2">
                  {c?.candidatestatus == "Not Applied"
                    ? ""
                    : c?.candidatestatus}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginTop: 6,
              }}
            >
              <Box
                className={`course_list_${c?.register
                  .replace(/ /g, "_")
                  .toLowerCase()}`}
                sx={{
                  color:
                    new Date(
                      `${c.lastDate.split("/").reverse().join("-")}T23:59`
                    ) >= new Date(new Date().setHours(23, 59, 0, 0))
                      ? "green"
                      : "red",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor:
                    new Date(
                      `${c.lastDate.split("/").reverse().join("-")}T23:59`
                    ) >= new Date(new Date().setHours(23, 59, 0, 0)) &&
                    "pointer",
                }}
                onClick={
                  new Date(`${c.lastDate.split("/").reverse().join("-")}T23:59`) >=
                  new Date(new Date().setHours(23, 59, 0, 0))
                    ? 
                    () => {
                        
                        if (c?.candidatestatus == "Not Applied") {
                          navigate(
                            `/${Cookies.get("mhet_cnd_project")}/pre-request`,
                            { state: { courseid: c?.courseid, coursename:c?.name } }
                          );
                          
                        } else {
                          navigate(
                            `/${Cookies.get("mhet_cnd_project")}/course-registration?page=personalinformation`,
                            { state: { courseid: c?.courseid, coursename:c?.name } }
                          );
                        }
                      }
                    : null
                }
              >
                {new Date(
                  `${c.lastDate.split("/").reverse().join("-")}T23:59`
                ) >= new Date(new Date().setHours(23, 59, 0, 0))
                  ? "Register Now"
                  : "Registration Closed"}
              </Box>

              {c?.lastDate && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "rgba(80, 78, 80, 1)",
                  }}
                >
                  Last Date - {c?.lastDate}
                </Typography>
              )}
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

function transformCourses(courses) {
  const result = [];
  const driveMap = new Map();

  const activeCourses = courses?.filter((course) => course.isactive === "1");

  console.log(activeCourses, "courseList6666666s");

  activeCourses.forEach((course) => {
    const {
      courseid,
      coursecategory,
      coursename,
      regstartdate,
      regenddate,
      corcandidateid,
      ispaid,
      brochurelocation,
      drivename,
      candidatestatus,
    } = course;

    if (!driveMap.has(drivename)) {
      driveMap.set(drivename, new Map());
    }

    const categoryMap = driveMap.get(drivename);
    if (!categoryMap.has(coursecategory)) {
      categoryMap.set(coursecategory, []);
    }

    let registerStatus = getRegistrationStatus(
      corcandidateid,
      ispaid,
      regstartdate,
      regenddate
    );

    const lastDate = regenddate
      ? new Date(regenddate).toLocaleDateString("en-GB")
      : "";
    const dateRange = formatDateRange(regstartdate, regenddate);

    categoryMap.get(coursecategory).push({
      name: coursename,
      date: dateRange,
      register: registerStatus,
      brochurelocation,
      courseid,
      lastDate,
      drivename,
      isactive: course.isactive,
      candidatestatus,
    });
  });

  driveMap.forEach((categoryMap, drive) => {
    const categories = [];
    categoryMap.forEach((courses, courseCategory) => {
      categories.push({
        courseCategory,
        courses,
      });
    });

    result.push({
      drive,
      categories,
    });
  });
  console.log(result, "result");
  return result;
}

function getRegistrationStatus(
  corcandidateid,
  ispaid,
  regstartdate,
  regenddate
) {
  const startDate = new Date(
    `${regstartdate.split("/").reverse().join("-")}T00:01`
  );
  const endDate = new Date(
    `${regenddate.split("/").reverse().join("-")}T23:59`
  );
  const currentDate = new Date();

  if (corcandidateid) {
    return ispaid === "1" ? "Already registered" : "Payment to be done";
  }

  if (new Date(startDate) > currentDate) {
    return "Registration yet to start";
  }

  if (new Date(endDate) < currentDate) {
    return "Registration closed";
  }

  return "Register Now"; // Default case
}

function formatDateRange(regstartdate, regenddate) {
  if (regstartdate && regenddate) {
    const startDate = new Date(regstartdate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const endDate = new Date(regenddate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return `${startDate}-${endDate}`;
  }
  return "";
}
