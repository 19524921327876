import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import YesNoButton from "../../common/button/yesNoButton";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


export default function PayApplicationFeeForm() {
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  console.log(courseid);
  const navigate = useNavigate();
  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh", 
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant="h4"
        sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 }}
      >
        Self Confirmation
      </Typography>
      <Typography
        variant="h4"
        sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500, color: "red" }}
      >
        Note
      </Typography>
      <Grid container>
        <Grid item xs={12} md={11} mt={1}>
          <p
            style={{
              color: "red",
              backgroundColor: "#FCDEDC",
              border: "1px solid #F0A8B2",
              minHeight: "5rem",
              borderRadius: "8px",
              fontSize: "16px",
              padding: "16px",
            }}
          >
            • Please check the details filled in the apllication form carefully
            before making the payment. After making payment, you will not be
            able to make any changes in the application form details.
          </p>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography sx={{ textWrap: "wrap", fontSize: 25,textAlign:"center" }}>
            Are you sure to proceed to payment?
          </Typography>
          <Box sx={{display:"flex",gap:"8px",justifyContent:"center"}} mt={2}>
            <YesNoButton name="Yes" handleSubmit={()=>navigate(`/${Cookies.get("mhet_cnd_project")}/confirm-payment`,{ state: { courseid: courseid,coursename: coursename } })}/>
            <YesNoButton name="No" handleSubmit={()=>navigate(`/${Cookies.get("mhet_cnd_project")}/course-selection`,{ state: { courseid: courseid,coursename: coursename } })} />
            {/* <YesNoButton name="Yes" handleSubmit={()=>navigate(`/${Cookies.get("mhet_cnd_project")}/confirm-payment`,{state:{courseid:courseid}})}/>
            <YesNoButton name="No" handleSubmit={()=>navigate(`/${Cookies.get("mhet_cnd_project")}/course-selection`)} /> */}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
