import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from '../../common/button/cancelButton'
import { MobileNumberFirstNumberValidation, MobileNumberValidation } from "../../utils/validations";
import { useForm } from "react-hook-form";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import OTPInput from "react-otp-input";
import PrimaryButton from "../../common/primaryButton";
import { useDispatch } from "react-redux";
import authActions from "../../redux/auth/actions";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import  Cookies  from 'js-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

const inputStyle = {
    width: "45px",
    height: "46px",
    margin: "8px",
    borderRadius: "8px",
    border: "1px solid #D5D4DC",
};

export default function EnterMobilenoModal(props) {

    const { handleClose, showModal } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [otp, setOtp] = useState("");
    const [showEnterOTP, setShowEnterOTP] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(120);
    const [error, setError] = useState("");

    const handleShowOtpInput = () => {
        setShowEnterOTP(true);
    };

    const onSubmit = (data) => {
        Cookies.set('mhet_cnd_forgetemail_mobileno',EncryptFunction(data?.mobileno))
        dispatch({ type: authActions.CHECK_CANDIDATE_MOBILENO, payload: { data: data?.mobileno, handleShowOtpInput: handleShowOtpInput } });
    };

    useEffect(() => {
        let timer;
        if (resendCountdown > 0) {
            timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendCountdown]);

    useEffect(() => {
        if (otp.length === 6) {
            handleVerify()
        }
    }, [otp])

    const handleVerify = () => {
        const otpValue = DecryptFunction(Cookies.get('mhet_cnd_forgetemail_otp'));
        let mobileno = DecryptFunction(Cookies.get('mhet_cnd_forgetemail_mobileno'))
        const modifyOtp = otp.split("");
        if (modifyOtp.length === 6) {
            if (modifyOtp.join("") === otpValue) {
                Cookies.remove('mhet_cnd_forgetemail_otp')
                dispatch({ type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION, payload: { validated: true } });
                dispatch({ type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID, payload: { data: mobileno,navigate:navigate } });
                handleCloseModal()
                Cookies.remove('mhet_cnd_forgetemail_mobileno')
                // dispatch({ type: authActions.VERIFY_MOBILENO_FOR_SIGNUP, payload: { data: { token: Cookies.get('mhet_cnd_temptoken') }, navigate: navigate } })
                // navigate(`/${Cookies.get('mhet_cnd_project')}/mobileno-verification`);
            } else {
                setError("Invalid OTP");
            }
        } else {
            setError("Enter valid OTP");
        }
    };

    const handleResendOTP = () => {
        setResendCountdown(60);
    };

    const handleCloseModal = () => {
        setShowEnterOTP(false);
        reset();
        setResendCountdown(60);
        setError('');
        handleClose();
    };

    return (
        <Modal
            open={showModal}
            // onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant="h5" sx={{ color: `${cssProperties?.color?.primary}`, fontWeight: '500' }}>Enter Mobile Number</Typography>
                <Box sx={{ backgroundColor: 'rgba(241, 91, 80, 0.2)', border: '1px solid rgba(240, 168, 178, 1)', color: 'rgba(241, 91, 80, 1)', borderRadius: '8px', margin: '24px 0', padding: '12px' }}>
                    <Typography variant="body2">Enter your mobile number to get otp. You can get your email id through sms after otp verification</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            size="small"
                            {...register("mobileno", {
                                required: "Mobile number is required",
                                minLength: {
                                    value: 10,
                                    message: "Enter valid mobile number",
                                },
                                validate: MobileNumberFirstNumberValidation,
                            })}
                            onInput={MobileNumberValidation}
                            inputProps={{ maxLength: 10 }}
                            label={
                                <span>
                                    Mobile no
                                    <span className="error" style={{ color: "red" }}>
                                        {" "}
                                        *
                                    </span>
                                </span>
                            }
                            placeholder="Enter mobile number"
                            fullWidth
                            error={!!errors.mobileno}
                            helperText={errors.mobileno ? errors.mobileno.message : ""}
                            FormHelperTextProps={{
                                style: { margin: 0 },
                            }}
                        />
                    </Grid>
                    {showEnterOTP &&
                        <Grid item md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <OTPInput
                                inputStyle={inputStyle}
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                            />
                        </Grid>
                    }
                </Grid>
                {!showEnterOTP &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                        <CancelButton handleBack={handleCloseModal} title={'Close'} />
                        <BluePrimaryButton title={"Submit"} handleSubmit={handleSubmit(onSubmit)} />
                    </Box>
                }
                {showEnterOTP &&
                    <>
                        <Box>
                            <Typography variant="body2" sx={{ color: 'red', marginTop: '12px' }}>
                                {error && <small className="error">{error}</small>}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '12px' }}>
                            <CancelButton handleBack={handleCloseModal} title={'Close'} />
                            <BluePrimaryButton
                                title={resendCountdown === 0 ? "Resend OTP" : "verify"}
                                handleSubmit={resendCountdown === 0 ? handleResendOTP : handleVerify}
                            // disabled={resendCountdown === 0 ? true : false}
                            />
                        </Box>
                        <Box sx={{ marginTop: '12px', textAlign: 'right' }}>
                            <Typography variant="body2" sx={{ color: 'red' }}>
                                {resendCountdown > 0 ? `${resendCountdown}s` : "0s"} Time out, request to resend OTP
                            </Typography>
                        </Box>
                        {/* <Box className="verify-resend" my={4} display="flex" justifyContent="start">
                            {PrimaryButton({ title: "verify", onClick: handleVerify, disabled: resendCountdown === 0 ? true : false })}
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E1E8F5',
                                    color: '#3F41D1',
                                    borderRadius: '40px',
                                    ml: 3,
                                    boxShadow: "none",
                                    '&:hover': {
                                        backgroundColor: '#E1E8F5',
                                        boxShadow: "none"
                                    }
                                }}
                                disabled={resendCountdown > 0}
                                onClick={handleResendOTP}
                            >
                                Resend OTP
                            </Button>
                        </Box> */}
                    </>
                }
            </Box>
        </Modal >
    )
};