const   candidatesActions = {
    SHOW_IMAGE:'SHOW_IMAGE',
    SHOW_COMPRESSED_IMAGE :'SHOW_COMPRESSED_IMAGE',
    SHOW_SIGNATURE:'SHOW_SIGNATURE',
    SHOW_COMPRESSED_SIGNATURE:'SHOW_COMPRESSED_SIGNATURE',
    PROFILE_PERSONAL_DETAILS_CREATION: 'PROFILE_PERSONAL_DETAILS_CREATION',
    GET_PROFILE_PERSONAL_DETAILS : 'GET_PROFILE_PERSONAL_DETAILS',
    SET_PROFILE_PERSONAL_DETAILS : 'SET_PROFILE_PERSONAL_DETAILS',
    PROFILE_ADDRESS_DETAILS_CREATION: 'PROFILE_ADDRESS_DETAILS_CREATION',
    PROFILE_CATEGORY_DETAILS_CREATION: 'PROFILE_CATEGORY_DETAILS_CREATION',
    PROFILE_EDUCATION_DETAILS_CREATION: 'PROFILE_EDUCATION_DETAILS_CREATION',
    PROFILE_EXAM_DETAILS_CREATION: 'PROFILE_EXAM_DETAILS_CREATION',
    PROFILE_CERTIFICATES_DETAILS_CREATION: 'PROFILE_CERTIFICATES_DETAILS_CREATION',
    PROFILE_UPLOADS_DETAILS_CREATION: 'PROFILE_UPLOADS_DETAILS_CREATION',
    PROFILE_UPLOADS_DETAILS_DRAFT_CREATION: 'PROFILE_UPLOADS_DETAILS_DRAFT_CREATION',
    GET_ADDRESS_PERSONAL_DETAILS : 'GET_ADDRESS_PERSONAL_DETAILS',
    SET_ADDRESS_PERSONAL_DETAILS : 'SET_ADDRESS_PERSONAL_DETAILS',
    GET_CATEGORY_PERSONAL_DETAILS : 'GET_CATEGORY_PERSONAL_DETAILS',
    SET_CATEGORY_PERSONAL_DETAILS : 'SET_CATEGORY_PERSONAL_DETAILS',
    GET_EDUCATION_PERSONAL_DETAILS : 'GET_EDUCATION_PERSONAL_DETAILS',
    SET_EDUCATION_PERSONAL_DETAILS : 'SET_EDUCATION_PERSONAL_DETAILS',
    GET_EXAM_PERSONAL_DETAILS : 'GET_EXAM_PERSONAL_DETAILS',
    SET_EXAM_PERSONAL_DETAILS : 'SET_EXAM_PERSONAL_DETAILS',
    GET_CERTIFICATE_PERSONAL_DETAILS : 'GET_CERTIFICATE_PERSONAL_DETAILS',
    SET_CERTIFICATE_PERSONAL_DETAILS : 'SET_CERTIFICATE_PERSONAL_DETAILS',
    PROFILE_PERSONAL_DETAILS_DRAFT_CREATION: 'PROFILE_PERSONAL_DETAILS_DRAFT_CREATION',
    PROFILE_ADDRESS_DETAILS_DRAFT_CREATION: 'PROFILE_ADDRESS_DETAILS_DRAFT_CREATION',
    PROFILE_CATEGORY_DETAILS_DRAFT_CREATION: 'PROFILE_CATEGORY_DETAILS_DRAFT_CREATION',
    PROFILE_EDUCATION_DETAILS_DRAFT_CREATION: 'PROFILE_EDUCATION_DETAILS_DRAFT_CREATION',
    PROFILE_EXAM_DETAILS_DRAFT_CREATION: 'PROFILE_EXAM_DETAILS_DRAFT_CREATION',
    PROFILE_CERTIFICATE_DETAILS_DRAFT_CREATION: 'PROFILE_CERTIFICATE_DETAILS_DRAFT_CREATION',
    GRIEVANCE_QUERY_CREATION : 'GRIEVANCE_QUERY_CREATION',
    GET_QUERY_STATUS_BY_CANDIDATEID : 'GET_QUERY_STATUS_BY_CANDIDATEID',
    SET_QUERY_STATUS_BY_CANDIDATEID : 'SET_QUERY_STATUS_BY_CANDIDATEID',
    GET_OTHER_PERSONAL_DETAILS : 'GET_OTHER_PERSONAL_DETAILS',
    SET_OTHER_PERSONAL_DETAILS : 'SET_OTHER_PERSONAL_DETAILS',
    PROFILE_OTHER_DETAILS_DRAFT_CREATION: 'PROFILE_OTHER_DETAILS_DRAFT_CREATION',
    PROFILE_OTHER_DETAILS_CREATION: 'PROFILE_OTHER_DETAILS_CREATION',
    GET_UPLOAD_PERSONAL_DETAILS : 'GET_UPLOAD_PERSONAL_DETAILS',
    SET_UPLOAD_PERSONAL_DETAILS : 'SET_UPLOAD_PERSONAL_DETAILS',
    SET_PINCODE:'SET_PINCODE',
    GET_PINCODE :'GET_PINCODE',
    SET_PINCODE_CORRESPONDENCE:'SET_PINCODE_CORRESPONDENCE',
    GET_PINCODE_CORRESPONDENCE :'GET_PINCODE_CORRESPONDENCE',
    GET_FULL_CANDIDATE_DETAILS:'GET_FULL_CANDIDATE_DETAILS',
    SET_FULL_CANDIDATE_DETAILS:'SET_FULL_CANDIDATE_DETAILS',
    COURSE_PERSONAL_DETAILS_CREATION : 'COURSE_PERSONAL_DETAILS_CREATION',
    COURSE_ADDRESS_DETAILS_CREATION : 'COURSE_ADDRESS_DETAILS_CREATION',
    COURSE_ACADEMIC_DETAILS_CREATION : 'COURSE_ACADEMIC_DETAILS_CREATION',
    COURSE_RESERVATION_DETAILS_CREATION : 'COURSE_RESERVATION_DETAILS_CREATION',
    COURSE_EXAM_DETAILS_CREATION : 'COURSE_EXAM_DETAILS_CREATION',
    GET_COURSE_REGISTRATION_DETAILS : 'GET_COURSE_REGISTRATION_DETAILS',
    SET_COURSE_REGISTRATION_DETAILS : 'SET_COURSE_REGISTRATION_DETAILS',
    GET_COURSE_APPLICANTID : 'GET_COURSE_APPLICANTID',
    SET_COURSE_APPLICANTID : 'SET_COURSE_APPLICANTID',
    SET_COURSE_REGISTRATION_DETAILS : 'SET_COURSE_REGISTRATION_DETAILS',
    GET_COURSE_STATUS : 'GET_COURSE_STATUS',
    SET_COURSE_STATUS : 'SET_COURSE_STATUS',
    GET_IS_PAID : 'GET_IS_PAID',
    SET_IS_PAID : 'SET_IS_PAID',
    GET_PAYMENT_RECEIPT_LIST : 'GET_PAYMENT_RECEIPT_LIST',
    SET_PAYMENT_RECEIPT_LIST : 'SET_PAYMENT_RECEIPT_LIST',
    GET_ALL_COURSE_LIST : 'GET_ALL_COURSE_LIST',
    SET_ALL_COURSE_LIST : 'SET_ALL_COURSE_LIST',
    GET_COURSE_TAB : 'GET_COURSE_TAB',
    SET_COURSE_TAB : 'SET_COURSE_TAB',
    SET_MASTER_FIELDS : 'SET_MASTER_FIELDS',
    GET_MASTER_FIELDS : 'GET_MASTER_FIELDS',
    GET_COURSE_ACTIVE_TAB : 'GET_COURSE_ACTIVE_TAB',
    SET_COURSE_ACTIVE_TAB : 'SET_COURSE_ACTIVE_TAB',
    GET_EXAM_STATE : 'GET_EXAM_STATE',
    SET_EXAM_STATE : 'SET_EXAM_STATE',
    GET_EXAM_DISTRICT : 'GET_EXAM_DISTRICT',
    SET_EXAM_DISTRICT : 'SET_EXAM_DISTRICT',
    GET_EXAM_LANGUAGE : 'GET_EXAM_LANGUAGE',
    SET_EXAM_LANGUAGE : 'SET_EXAM_LANGUAGE',
    GET_COURSE_FORM_DETAILS :'GET_COURSE_FORM_DETAILS',
    SET_COURSE_FORM_DETAILS :'SET_COURSE_FORM_DETAILS',
    GET_CURRENT_COURSE :'GET_CURRENT_COURSE',
    SET_CURRENT_COURSE :'SET_CURRENT_COURSE',
    COURSE_PREVIEW_UPDATION : 'COURSE_PREVIEW_UPDATION',
    CREATE_PRINT_LOG : 'CREATE_PRINT_LOG',
};

export default candidatesActions;