import { Grid, Box } from "@mui/material";
import React, { useRef } from "react";
import PreviewForm from "./previewForm";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { IoMdDownload } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";

export default function PrintApplicationForm() {
  const location = useLocation();
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const courseid = location.state?.courseid;
  const targetRef = useRef();
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const handleDownloadPDF = () => {
    const input = targetRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
  
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('application_form.pdf');
    });
  };

  const handlePrintLog = () => {
    dispatch({ type:candidatesActions.CREATE_PRINT_LOG,
      payload: { data : { candidateid : candidateid, courseid: courseid }}
    })

  }

  return (
    <Grid
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid
        container
        pr={3}
        pl={3}
        pt={2}
        sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
      >
        {/* <PrintDownloadButton
          name="Download Application Form"
          startIcon={<IoMdDownload />}
          handleSubmit={handleDownloadPDF}
        /> */}
        <PrintDownloadButton
          name="Print Application Form"
          startIcon={<MdPrint />}
          handleSubmit={() => {
            handlePrint();
            handlePrintLog();
          }}
        />
      </Grid>
      <Grid>
        <Box>
          <PreviewForm courseId={courseid} targetRef={targetRef} />
        </Box>
      </Grid>
    </Grid>
  );
}
