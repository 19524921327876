import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import masterActions from "../../redux/master/action";
import {
  percentageValidation,
  percentageNumberValidation,
  markValidation,
} from "../../utils/validations";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";

export default function OtherDetailsForm({ preprocessFormData }) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const date = new Date();
  const currentYear = date.getFullYear();
  const [years, setYears] = useState([]);
  const [hscYears, setHscYears] = useState([]);
  const [hscStartYr,setHscStartYr] = useState(1994);
  const [disability, setDisability] = useState("");
  const [minorityDetails, setMinorityDetails] = useState("");
  const [scribe, setScribe] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [marksObtained, setMarksObtained] = useState("");
  const [marksOutOf, setMarksOutOf] = useState("");

  const watchMarksObtained = watch("hscmarksobtained");
  const watchMarksOutOf = watch("hscmarksoutof");

  const handleDisability = (e) => {

    console.log('is dis---',watch("isdisability"));

    setValue("isdisability", parseInt(e.target.value));
    setDisability(e.target.value);
    dispatch({
      type: masterActions.SET_PWDSUB_TYPE,
      payload: [],
    });
    trigger("isdisability");
  
      setValue("disabilitytypeid", "");
      setValue("subdisabilitytypeid", "");
      setValue("isscriberequired", "");
      setValue("isextratimerequired", "");
  };

  const handleMinority = (e) => {
    setValue("isminority", parseInt(e.target.value));
    setMinorityDetails(e.target.value);
    trigger("isminority");
    if (e.target.value === 0) {
      setValue("linguisticminorityid", "");
      setValue("religiousminorityid", "");
    }
  };

  // const handleScribe = (e) => {
  //   setValue("isscriberequired", parseInt(e.target.value));
  //   setScribe(e.target.value);
  //   trigger("isscriberequired");
  //   if (e.target.value === 0) {
  //     setValue("isextratimerequired", "");
  //   }
  // };

  const handleBack = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=address`);
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { minority, pwdType, pwdSubType, board, state } = useSelector(
    (state) => state.masterReducer
  );
  console.log(pwdSubType, "pwdSubType");

  const [filteredStates, setFilteredStates] = useState(state);

  const watchedBoardId = useWatch({ control, name: "sscboardid" });
  useEffect(() => {
    console.log('isscriberequired----',watch('isscriberequired'));
  
    const selectedBoard = board.find((b) => b.boardid === watchedBoardId);
    if (selectedBoard) {
      const boardStateId = selectedBoard.boardstateid;
      const filteredStates = state.filter(
        (s) => s.boardstateid === boardStateId
      );
      setFilteredStates(filteredStates);
    } else {
      setFilteredStates(state);
      setValue("sscschoolstateid", "");
    }
  }, [watchedBoardId, board, state, setValue]);

  // useEffect(() => {
  //   // console.log('hscp gyear----',hscStartYr);
  //   // Check if markstype is not 2
  //   if (watch("marktype") === 2) {
  //     if (watchMarksObtained && watchMarksOutOf) {
  //       const obtained = parseFloat(watchMarksObtained);
  //       const outOf = parseFloat(watchMarksOutOf);
  //       if (!isNaN(obtained) && !isNaN(outOf) && outOf > 0) {
  //         const percentage = (obtained / outOf) * 100;
  //         setValue("hscpercentage", percentage.toFixed(2));
  //       } else {
  //         setValue("hscpercentage", "");
  //       }
  //     }
  //   } else {
  //     // Clear the percentage value if markstype is 2
  //     setValue("hscpercentage", "");
  //   }
  // }, [watchMarksObtained, watchMarksOutOf, setValue, watch]);
  
  useEffect(() => {
    // Check if markstype is not 2
    if (watch("marktype") === 2) {
      if (watchMarksObtained && watchMarksOutOf) {
        const obtained = parseFloat(watchMarksObtained);
        const outOf = parseFloat(watchMarksOutOf);
        
        if (!isNaN(obtained) && !isNaN(outOf) && outOf > 0) {
          let percentage = (obtained / outOf) * 100;
  
          // Ensure percentage is not greater than 100
          if (percentage > 100) {
            percentage = 0;
          }
  
          setValue("hscpercentage", percentage.toFixed(2));
        } else {
          setValue("hscpercentage", "");
        }
      }
    } else {
      // Clear the percentage value if markstype is not 2
      setValue("hscpercentage", "");
    }
  }, [watchMarksObtained, watchMarksOutOf, setValue, watch]);
  
  

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { otherDetails } = useSelector((state) => state.candidatesReducer);
  const candidateotherdetailsid = otherDetails[0]?.candidateotherdetailsid;
  const candidateotherdetailsdraftid =
    otherDetails[0]?.candidateotherdetailsdraftid;

    
  useEffect(() => {
    
console.log('otherDetails[0]--',otherDetails[0]);
    if (otherDetails.length > 0) {
      if (otherDetails[0].status === 2) {
        console.log(otherDetails[0].status, "status");
        setDisabled(true);
      } else {
        console.log(otherDetails[0].status, "status false");
        setDisabled(false);
      }
      console.log('otherDetails[0]----',otherDetails);
      console.log('apperaingfor----', parseInt(watch("appearingfor")));
      reset({ ...otherDetails[0] });

      setValue("linguisticminorityid", otherDetails[0]?.linguisticminorityid);
      setValue("isminority", otherDetails[0]?.isminority);
      setValue("isscriberequired", parseInt(otherDetails[0]?.isscriberequired));
      setValue(
        "isextratimerequired",
        parseInt(otherDetails[0]?.isextratimerequired)
      );
      setTimeout(()=>{
      setValue("disabilitytypeid",getValues('isdisability') == 0 ? "" : otherDetails[0]?.disabilitytypeid); 
      setValue("subdisabilitytypeid",getValues('isdisability') == 0 ? "" :  otherDetails[0]?.subdisabilitytypeid);
      console.log("disabilitytypeid----", getValues("disabilitytypeid"));

      if(getValues("disabilitytypeid") != null || getValues("disabilitytypeid") != "" ){
    
    dispatch({
      type: masterActions.GET_PWDSUB_TYPE,
      payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
    });
  }
    
  },1000)
      
    }

  }, [otherDetails]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    // dispatch({ type: masterActions.GET_PWDSUB_TYPE });
   
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_MINORITY });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
   

  
  }, [candidateid]);


  useEffect(()=>{

    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = 1994; i <= currentYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    };
    setYears(generateYears());
  
    const generateHscYears = () =>{

      console.log(`getValues('hscpassingyear')---`,getValues('sscpassingyear'));
      const startYear = parseInt(getValues('sscpassingyear')) + 2 ?? hscStartYr ;
      // const startYear = watch('sscpassingyear')
      console.log('startYear--',startYear);
      const currentYear = new Date().getFullYear();
      const sscYearsArray = [];
      for (let i = startYear ? startYear : 1994; i <= currentYear; i++) {
        sscYearsArray.push(i);
      }
      return sscYearsArray;
    
    }
  
    setHscYears(generateHscYears())
  

  },[watch('sscpassingyear')])

  const handleDraft = (getValues) => {
    console.log('getValues--',getValues);
    const values = preprocessFormData(getValues());
    let operation = candidateotherdetailsdraftid ? 1 : 0;
    // let operation = candidateotherdetailsid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: candidateotherdetailsid ? 2: 1,
          operation: operation,
          candidateid: candidateid,
        },
      },
    });

    setTimeout(() => {
      
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }, 1000);
 
 setEditMode(false);
  };


  const handleSave = (data) => {
    console.log("clicked");
trigger('isscriberequired');
// console.log( `(watch('isdisability') == 1 && watch('isscriberequired') == 2)---`,getValues('isdisability') == 1 && (getValues('isscriberequired') != 0 || getValues('isscriberequired') != 1));
// if(getValues('isdisability') == 1 && (getValues('isscriberequired') != 0 || getValues('isscriberequired') != 1) ) return
console.log(`watch('isscriberequired')---`,watch('isscriberequired'));
console.log(`watch('isdisability')---`,watch('isdisability'));
    console.log("data------", data);
    let operation = candidateotherdetailsid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
          hscmarksoutof: watchMarksOutOf
          // ,
          // isextratimerequired:watch('isextratimerequired') == 1 ? 1 : watch('isextratimerequired') == 0 ? 0  : watch('isextratimerequired') == 2 ? "" :"",
          // isscriberequired:watch('isscriberequired') == 1 ? 1 : watch('isscriberequired') == 0 ? 0 : watch('isscriberequired') == 2 ? "" : ""
        },
        navigate: navigate,
      },
    });
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
  };

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };

  console.log("is mhccccc", watch("ismhFcandidate"));

  // const subdisabilitytypeid = watch("subdisabilitytypeid");
  // const isextratimerequired = watch(" ");

  // const getScribeRequirement = () => {
  //   const selectedSubType = pwdSubType?.find(
  //     (obj) => parseInt(obj.pwdsubtypeid) === parseInt(subdisabilitytypeid)
  //   );

  //   if (selectedSubType?.scriberequired !== 2) {
  //     return selectedSubType?.scriberequired;
  //   }

  //   return isextratimerequired ?? 0;
  // };

  const outOfMarkValidation = (input) => {
    let error = null;
  
    if (!input || input === "") {
      return error; // No error for empty input (use required validation separately)
    }
  
    const inputStr = input.toString();
  
    if (inputStr.includes('.')) {
      return 'Invalid input. Marks cannot have decimal places.';
    }
  
    const value = parseInt(inputStr, 10);
  
    if (isNaN(value)) {
      return 'Invalid input. Please enter a valid number.';
    }
  
    const obt = parseInt(watch('hscmarksobtained'), 10);
  
    if (isNaN(obt)) {
      return 'Please enter the obtained marks first.';
    }
  
    if (value < 0) {
      error = 'Invalid input. Marks cannot be negative.';
    } else if (value < obt) {
      error = 'Invalid input. "Out Of" marks cannot be less than "Obtained" marks.';
      // setValue('hscpercentage',0)
      // trigger('hscpercentage')
      
    } else if (inputStr.length > 4) {
      error = 'Invalid input. Marks cannot have more than four digits.';
    }
  
    return error;
  };
  
  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Grid>
        <Typography
          pb={2}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Other Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.ismhcandidate}>
              <InputLabel
                shrink={
                  !!watch("ismhcandidate") || watch("ismhcandidate") === 0
                }
                htmlFor="ismhcandidate"
              >
                Are you Domiciled in the state of Maharashtra?
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
               
                disabled={disabled}
                id="ismhcandidate"
                label="Are you Domiciled in the state of Maharashtra?"
                {...register("ismhcandidate", {
                  required: "MH Candidate is required",
                })}
                onChange={(e) => {
                  setValue("ismhcandidate", e.target.value);
                  trigger("ismhcandidate");
                }}
                value={watch("ismhcandidate") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.ismhcandidate && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ismhcandidate.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isminority}>
              <InputLabel
                shrink={!!watch("isminority") || watch("isminority") === 0}
                htmlFor="isminority"
              >
                Minority Details{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isminority"
                disabled={disabled}
                label="Minority Details"
                {...register("isminority", {
                  required: "Minority details is required",
                })}
                onChange={handleMinority}
                value={watch("isminority") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isminority && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isminority.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.linguisticminorityid}>
              <InputLabel
                shrink={!!watch("linguisticminorityid")}
                htmlFor="linguisticminorityid"
              >
                Linguistic Minority{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="linguisticminorityid"
                label="Linguistic Minority"
                {...register(
                  "linguisticminorityid"
                  // {
                  //   required:
                  //     minorityDetails !== 0
                  //       ? "Linguistic minority is required"
                  //       : false,
                  // }
                ,{
                  required: getValues("isminority") == 1 ? "Linguistic minority is required" : false,
                })}
                disabled={
                  getValues("isminority") &&
                  getValues("isminority") === 1 &&
                  !disabled
                    ? false
                    : true
                  // minorityDetails === 0 || getValues("isminority") === "0"
                }
                onChange={(e) => {
                  setValue("linguisticminorityid", e.target.value);
                  trigger("linguisticminorityid");
                }}
                value={watch("linguisticminorityid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {minorityDetails !== "0" &&
                  minority &&
                  minority.length > 0 &&
                  minority.map(
                    (item) =>
                      item.type === "Linguistic Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item?.minority}
                        </MenuItem>
                      )
                  )}
              </Select>
              {errors.linguisticminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.linguisticminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.religiousminorityid}>
              <InputLabel
                shrink={!!watch("religiousminorityid")}
                htmlFor="religiousminorityid"
              >
                Religious Minority{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="religiousminorityid"
                label="Religious Minority"
                {...register(
                  "religiousminorityid"
                  // {
                  //   required:
                  //     minorityDetails !== 0
                  //       ? "Religious minority is required"
                  //       : false,
                  // }
                  ,{
                    required: getValues("isminority") == 1 ? "Religious minority is required" : false,
                  }
                )}
                disabled={
                  getValues("isminority") &&
                  getValues("isminority") === 1 &&
                  !disabled
                    ? false
                    : true
                  // minorityDetails === 0 || getValues("isminority") === "0"
                }
                onChange={(e) => {
                  setValue("religiousminorityid", e.target.value);
                  trigger("religiousminorityid");
                }}
                value={watch("religiousminorityid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {minorityDetails !== "0" &&
                  minority?.map(
                    (item) =>
                      item.type === "Religious Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item.minority}
                        </MenuItem>
                      )
                  )}
              </Select>
              {errors.religiousminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religiousminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isdisability}>
              <InputLabel
                shrink={!!watch("isdisability") || watch("isdisability") === 0}
                htmlFor="isdisability"
              >
                Are you person with Disability?
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isdisability"
                disabled={disabled}
                label="Are you person with Disability?"
                {...register("isdisability", {
                  required: "Disability status is required",
                })}
                onChange={handleDisability}
                value={watch("isdisability") ?? ""}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isdisability && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isdisability.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.disabilitytypeid}>
              <InputLabel
                shrink={!!watch("disabilitytypeid")}
                htmlFor="disabilitytypeid"
              >
                Type of Disability?
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="disabilitytypeid"
                label="Type of Disability?"
                {...register("disabilitytypeid",{
                required: getValues("isdisability") == 1 ? "Disability type is required" : false,
                })
              }
                disabled={
                  disabled || watch("isdisability") === 0
                  // disability === 0 || getValues("isdisability") === "0"
                }
                onChange={(e) => {
                  setValue("disabilitytypeid", e.target.value);
                  trigger("disabilitytypeid");
                  setValue("subdisabilitytypeid","");
                  setValue("isscriberequired","");
                  setValue("isextratimerequired","");
                  const disabilitytypeid = e.target.value;
                  console.log(disabilitytypeid, "disabilitytypeid");
                  if (disabilitytypeid) {
                    dispatch({
                      type: masterActions.GET_PWDSUB_TYPE,
                      payload: { pwdTypeID: disabilitytypeid },
                    });
                  }
                }}
                value={watch("disabilitytypeid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {disability !== "0" &&
                  pwdType?.map((val, i) => (
                    <MenuItem value={val?.pwdtypeid} key={i}>
                      {val?.pwdtype}
                    </MenuItem>
                  ))}
              </Select>
              {errors.disabilitytypeid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.disabilitytypeid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.subdisabilitytypeid}>
              <InputLabel
                shrink={!!watch("subdisabilitytypeid")}
                htmlFor="subdisabilitytypeid"
              >
                SubType of Disability?
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                
                id="subdisabilitytypeid"
                label="SubType of Disability?"
                {...register("subdisabilitytypeid",{
                  required: getValues("isdisability") == 1 ? "Disability type is required" : false,
                })}
                disabled={disabled || watch("isdisability") == 0  }
                onChange={(e) => {
                  setValue("subdisabilitytypeid", e.target.value);
                  trigger("subdisabilitytypeid");
                  let extratimerequiredvalue = pwdSubType?.find(
                    (obj) =>
                      parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                  )?.extratimerequired;
                  setValue("isextratimerequired", extratimerequiredvalue);
                  let isscriberequiredvalue = pwdSubType?.find(
                    (obj) =>
                      parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                  )?.scriberequired;
                  setValue("isscriberequired", isscriberequiredvalue);
                  console.log(extratimerequiredvalue,isscriberequiredvalue,'ex,scri val')
                  console.log('isextratimerequired---',watch('isextratimerequired'));
                  console.log('isscriberequired---',watch('isscriberequired'));
                }}
                value={watch("subdisabilitytypeid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>

                {pwdSubType?.map((val, i) => (
                  <MenuItem value={val?.pwdsubtypeid} key={i}>
                    {val?.pwdsubtype}
                  </MenuItem>
                ))}
              </Select>
              {errors.subdisabilitytypeid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.subdisabilitytypeid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.isscriberequired}>
              <InputLabel
                shrink={
                  watch("isscriberequired") ||
                  parseInt(watch("isscriberequired")) === 0
                }
                htmlFor="isscriberequired"
              >
                Do You Require Scribe?{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="isscriberequired"
                disabled={
                  disabled ||
                 
                  (watch("subdisabilitytypeid") ?
                    pwdSubType.find(
                      (obj) =>
                        parseInt(obj.pwdsubtypeid) ===
                        parseInt(watch("subdisabilitytypeid"))
                    )?.scriberequired !== 2 : true) 
                }
                label="Do You Require Scribe?"
                {...register("isscriberequired", {
                  required: getValues("isdisability") == 1 ? "Scribe field is required" : false,
                })}
                value={
                   watch("isscriberequired") ?? ""
                //   //   ? watch("isscriberequired")
                //   //   :
                //   pwdSubType?.find(
                //     (obj) =>
                //       parseInt(obj.pwdsubtypeid) ===
                //       parseInt(watch("subdisabilitytypeid"))?.scriberequired
                //   ) !== 2
                //     ? parseInt(
                //         pwdSubType?.find(
                //           (obj) =>
                //             parseInt(obj.pwdsubtypeid) ===
                //             parseInt(watch("subdisabilitytypeid"))
                //         )?.scriberequired
                //       )
                //     : watch("isscriberequired") ?? 0
                }
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem  value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isscriberequired && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isscriberequired.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
  <FormControl fullWidth error={!!errors.isextratimerequired}>
    <InputLabel
      shrink={!!watch("isextratimerequired") || watch("isextratimerequired") === 0}
      htmlFor="isextratimerequired"
    >
      Do You Require Extra Time?{" "}
      <span className="error" style={{ color: "#666666" }}>
        {" "}
        *
      </span>
    </InputLabel>
    <Select
      MenuProps={{
        disableScrollLock: true,
    }}
      inputProps={{
        style: { height: "56px", boxSizing: "border-box" },
      }}
      id="isextratimerequired"
      label="Do You Require Extra Time?"
      {...register("isextratimerequired", {
        required: watch("isdisability") === 1
          ? "Is extra time required"
          : false,
      })}
      disabled={
        disabled ||
       
        (watch("subdisabilitytypeid") ?
          pwdSubType.find(
            (obj) =>
              parseInt(obj.pwdsubtypeid) ===
              parseInt(watch("subdisabilitytypeid"))
          )?.extratimerequired !== 2 : true)
      }
      value={watch("isextratimerequired") ?? ""}
    >
      <MenuItem value="">Select One</MenuItem>
      <MenuItem value={1}>Yes</MenuItem>
      <MenuItem value={0}>No</MenuItem>
    </Select>
    {errors.isextratimerequired && (
      <FormHelperText style={{ margin: 0 }}>
        {errors.isextratimerequired.message}
      </FormHelperText>
    )}
  </FormControl>
</Grid>

        </Grid>

        <Typography
          pb={2}
          pt={2}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Education Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscpassingyear}>
              <InputLabel
                shrink={!!watch("sscpassingyear")}
                htmlFor="sscpassingyear"
              >
                SSC Passing Year{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="sscpassingyear"
                label="SSC Passing Year"
                disabled={disabled}
                {...register("sscpassingyear", {
                  required: "SSC Passing Year is required",
                })}
                onChange={(e) => {
                  setValue("sscpassingyear", e.target.value);
                  setValue('hscpassingyear','')
                  setHscStartYr(parseInt(e.target.value));
                  trigger("sscpassingyear");
                  console.log('hscStartYr-----',hscStartYr);
                }}
                value={watch("sscpassingyear") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              fullWidth
              disabled={disabled}
              variant="outlined"
              type="text"
              label={
                <span>
                  SSC Total percentage
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("ssctotalpercentage", {
                required: "SSC Total percentage % is required",
                validate:percentageValidation,
              })}
              onInput={percentageNumberValidation}
              error={!!errors.ssctotalpercentage}
              helperText={errors.ssctotalpercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ssctotalpercentage"),
              }}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscboardid}>
              <InputLabel
                shrink={!!watch("sscboardid") || watch("sscboardid") === 0}
                htmlFor="sscboardid"
              >
                SSC Board{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="sscboardid"
                label="SSC Board"
                {...register("sscboardid", {
                  required: "SSC Board is required",
                })}
                onChange={(e) => {
                  const id = e.target.value;
                  const filterId = board?.find(
                    (row) => row.boardid === parseInt(id)
                  );
                  const finalId = filterId.boardstateid;
                  setValue("sscschoolstateid", finalId);
                  setValue("sscboardid", id);
                  clearErrors("sscboardid");
                }}
                value={watch("sscboardid") >= 0 ? watch("sscboardid") : ""}
              >
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.sscschoolstateid}>
              <InputLabel
                shrink={
                  !!watch("sscschoolstateid") || watch("sscschoolstateid") === 0
                }
                htmlFor="sscschoolstateid"
              >
                SSC School State{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="sscschoolstateid"
                label="SSC School State"
                {...register("sscschoolstateid", {
                  required: "SSC School State is required",
                })}
                onChange={(e) => {
                  setValue("sscschoolstateid", e.target.value);
                  trigger("sscschoolstateid");
                }}
                value={watch("sscschoolstateid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschoolstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschoolstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.appearingfor}>
              <InputLabel
                shrink={!!watch("appearingfor") || watch("appearingfor") === 0}
                htmlFor="appearingfor"
              >
                Are you Appearing/Appeared for HSC / Diploma exam in 2025?{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled}
                id="appearingfor"
                label={
                  <>
                    <span>
                      {/* Are you appearing/applied for 12th HSC Diploma exam in{" "} */}
                      Are you appearing/applied for  HSC / Diploma exam in{" "}
                      {currentYear}?
                    </span>
                    <span className="error" style={{ color: "#666666" }}>
                      {" "}
                      *
                    </span>
                  </>
                }
                {...register("appearingfor", {
                  required: "Required",
                })}
                onChange={(e) => {
                  setValue("appearingfor", e.target.value);
                  trigger("appearingfor");
                  if (e.target.value === 1) {
                    setValue("hscboardid", "");
                    setValue("hscpassingyear", "");
                    setValue("marktype", "");
                    setValue("hscmarksobtained", "");
                    setValue("hscmarksoutof", "");
                    setValue("hscpercentage", "");
                  }
                }}
                value={watch("appearingfor") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.appearingfor && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appearingfor.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscpassingyear}>
              <InputLabel
                shrink={!!watch("hscpassingyear")}
                htmlFor="hscpassingyear"
              >
                HSC / Diploma Passing Year{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="hscpassingyear"
                label="HSC Passing Year"
                disabled={disabled || parseInt(watch("appearingfor")) === 1}
                {...register("hscpassingyear", {
                  required:
                    watch("appearingfor") === 0
                      ? "HSC Passing Year is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("hscpassingyear", e.target.value);
                  trigger("hscpassingyear");
                }}
                value={watch("hscpassingyear") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {hscYears.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.hscboardid}>
              <InputLabel
                shrink={!!watch("hscboardid") || watch("hscboardid") === 0}
                htmlFor="hscboardid"
              >
                HSC / Diploma Board{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="hscboardid"
                label="HSC / Diploma Board"
                {...register("hscboardid", {
                  required:
                    // watch("appearingfor") === 0
                       " HSC / Diploma  Board is required"
                      // : false,
                })}
                onChange={(e) => {
                  setValue("hscboardid", e.target.value);
                  trigger("hscboardid");
                }}
                value={watch("hscboardid") ?? ""}
                disabled={
                  disabled 
                    
                }
              >
                <MenuItem value="">Select One</MenuItem>
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.marktype}>
              <InputLabel shrink={!!watch("marktype")} htmlFor="marktype">
                Mark Type{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="marktype"
                label="Mark Type"
                {...register("marktype", {
                  required:
                    watch("appearingfor") === 0
                      ? "Mark Type is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("marktype", e.target.value);
                }}
                value={watch("marktype") ?? ""}
                disabled={disabled || parseInt(watch("appearingfor")) === 1}
              >
                <MenuItem value="" disabled>
                  Select One
                </MenuItem>
                <MenuItem value={1}>CGPA</MenuItem>
                <MenuItem value={2}>Percentage</MenuItem>
              </Select>
              {errors.marktype && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.marktype.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              fullWidth
              disabled={disabled || parseInt(watch("appearingfor")) === 1}
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks Obtained
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks Obtained"
              {...register("hscmarksobtained", {
                required:
                  watch("appearingfor") === 0
                    ? "HSC / Diploma  Marks Obtained is required"
                    : false,
                validate: markValidation,
              })}
              onInput={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                e.target.value = value;
                setMarksObtained(value);
              }}
              error={!!errors.hscmarksobtained}
              helperText={errors.hscmarksobtained?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksobtained") }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                pattern: "[0-9]*",
              }}
              fullWidth
              disabled={disabled || parseInt(watch("appearingfor")) === 1}
              variant="outlined"
              type="text"
              label={
                <span>
                  HSC / Diploma Marks Out Of
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the HSC / Diploma Marks Out Of"
              {...register("hscmarksoutof", {
                required:
                  watch("appearingfor") === 0
                    ? "HSC / Diploma  Marks Out Of is required"
                    : false,
                validate:  outOfMarkValidation ,
              })}
              onInput={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                e.target.value = value;
              }}
              error={!!errors.hscmarksoutof}
              helperText={errors.hscmarksoutof?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscmarksoutof") }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                pattern: "[0-9]*",
                onKeyDown: (e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "E") {
                    e.preventDefault(); // Prevent negative signs and exponential inputs
                  }
                },
                onInput: (e) => {
                  if (e.target.value < 0) {
                    e.target.value = 0; // Ensure value doesn't go below 0
                  }
                },

              }}
              fullWidth
              
              disabled={
                disabled ||
                parseInt(watch("appearingfor")) === 1 ||
                watch("marktype") === 2
              }
              variant="outlined"
              type="number" // Allows both CGPA and Percentage to accept decimal numbers
              min="0"
              label={
                <span>
                  {"HSC / Diploma Percentage/CGPA"}{" "}
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder={
                watch("marktype") === 1
                  ? "Enter the CGPA"
                  : "Enter the HSC Total percentage"
              }
              {...register("hscpercentage", {
                required:
                parseInt(watch("appearingfor")) === 0
                    ? watch("marktype") === 1
                      ? "CGPA is required"
                      : "HSC / Diploma Total percentage % is required"
                    : false,
                validate: percentageValidation,
              })}
              error={!!errors.hscpercentage}
              helperText={errors.hscpercentage?.message}
              FormHelperTextProps={{ style: { margin: 0 } }}
              InputLabelProps={{ shrink: !!watch("hscpercentage") }}
              InputProps={{ inputMode: "decimal" }} // Specifies decimal input mode
            />
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box sx={{ display: "flex", gap: "8px" }}>
            {/* <SaveButton
              name={"Edit"}
              handleDraft={() => {
                setDisabled(false);
                setEditMode(true);
              }}
            />
            <BackButton name={"clear"} handleBack={handleCancel} />
            {disabled ? (
              <SaveButton
                name={"Save As Draft"}
                handleDraft={() => setDisabled(false)}
              />
            ) : (
              <SaveButton
                name={draftButtonLoader ? "loader" : "Save As Draft"}
                handleDraft={() => handleDraft(getValues)}
              />
            )}
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            /> */}

            {otherDetails[0]?.status !== 1 && otherDetails[0]?.status !== 2 && (
              <>
                {/* <BackButton name={"clear"} handleBack={handleCancel} /> */}
                <SaveButton name={"Save As Draft"} handleDraft={() => handleDraft(getValues)} />
                <SubmitButton
                  name={buttonLoader ? "loader" : "Next"}
                  handleSubmit={handleSubmit(handleSave)}
                />
              </>
            )}

            {editMode && (
              <>
                {/* <BackButton name={"clear"} handleBack={handleCancel} /> */}
                <SaveButton name={"Save As Draft"}  handleDraft={() => handleDraft(getValues)} />
                <SubmitButton
                  name={buttonLoader ? "loader" : "Next"}
                  handleSubmit={handleSubmit(handleSave)}
                />
              </>
            )}

            {!editMode &&
              (otherDetails[0]?.status === 1 ||
                otherDetails[0]?.status === 2) && (
                <>
                  <SaveButton
                    name={"Edit"}
                    handleDraft={() => {
                      setDisabled(false);
                      setEditMode(true);
                    }}
                  />
                </>
              )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
