import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { Typography } from "@mui/material";
import PageLoader from "../pageLoader";
import commonActions from "../../redux/common/actions";

export default function VerifyEmailRedirection() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    console.log(token,'token');
    

    useEffect(() => {
        dispatch({ type: commonActions.SET_PAGE_LOADER, payload: true });
        if (token) {
            dispatch({ type: authActions.VERIFY_EMAILID_FOR_SIGNUP, payload: { data: { token: token }, navigate: navigate } })
        }
    }, [token]);

    return (
        <></>
        // <Typography variant="h4">Loading...</Typography>
    )
};