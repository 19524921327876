import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import Index from "../components/paymentConfirmation";
import {  NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";

export default function PaymentConfirmation() {
const navigate =useNavigate();

    const location = useLocation();
    const courseid = location.state?.courseid
    const coursename = location.state?.coursename;
    
    console.log('coursename in paymentconfirmation page---',coursename);

    const breadcrumbs = [
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
        }}>
            Dashboard
        </Link>,
    
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/course-selection`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
    
        }}>
            Course selection
        </Link>,
        <Link underline="hover" key="1" style={{textDecoration:"none"}}  
        onClick={()=>  navigate(
            `/${Cookies.get(
              "mhet_cnd_project"
            )}/course-registration?page=personalinformation`,
            { state: { courseid: courseid,coursename:coursename} }
          )}
        
        // to={`/${Cookies.get('mhet_cnd_project')}/course-registration`} 
        
        sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color:cssProperties?.fontcolor?.primary,
            cursor:"pointer"
        }}>
            {coursename}
        </Link>,
    
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: "bold",
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Payment confirmation<MdKeyboardArrowDown size={16} />
        </Typography>,
    ];


    return (
        <>
            <Layout>
                <Header breadcrumbs={breadcrumbs} />
                <Sidebar />
                <Index />
            </Layout>
        </>
    )
}