import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import verifyImage from "../../assets/images/verify.png";
import {
  DecryptFunction,
  DecryptObjectFunction,
} from "../../utils/cryptoFunction";
import PrimaryButton from "../../common/button/primaryButton";
import { hashMobile } from "../../utils/validations";
import OTPInput from "react-otp-input";
import { cssProperties } from "../../utils/commonCssProperties";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import commonActions from "../../redux/common/actions";


export default function EmailVerifications(props) {
  const { setResendCountdown, resendCountdown } = props
  const navigate = useNavigate();
  const [attempt, setAttempt] = useState(0);
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.commonReducer);
  const theme = useTheme();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const otpValue = Cookies.get("mhet_cnd_mobileno_otp")
  const modifyOtp = otp.split("");
  const [wrongOTP, setWrongotp] = useState(0);
  const [red, setRed] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null);


  useEffect(() => {
    if (Cookies.get("mhet_cnd_email_verified") === true) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/verify-mobileno`);
    }
  }, []);

  const handleCheckEmailVerification = () => {
    if (Cookies.get("mhet_cnd_temptoken")) {
      dispatch({
        type: authActions.CHECK_EMAIL_VERIFICAITON,
        payload: {
          data: { token: Cookies.get("mhet_cnd_temptoken") },
          navigate: navigate,
        },
      });
    }
  };

  const handleResendEmail = () => {
    if (Cookies.get("mhet_cnd_temptoken")) {
      dispatch({
        type: authActions.RESEND_EMAIL_FOR_VERIFICATION,
        payload: { data: { token: Cookies.get("mhet_cnd_temptoken") } },
      });
    }
  };

 
  useEffect(() => {
    if (Cookies.get("mhet_cnd_mobileno_verified") === true) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/`);
    }
    console.log(Math.random(6, 100000000));
  }, []);

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);



  const handleVerify = () => {
    const otpValue = DecryptFunction(Cookies.get("mhet_cnd_mobileno_otp"));
    const modifyOtp = otp.split("");
    dispatch({
      type: authActions.VERIFY_MOBILENO_FOR_SIGNUP,
      payload: {
        data: { token: Cookies.get("mhet_cnd_temptoken"), mobileno: Cookies.get("mhet_cnd_mobileno"), otp: otp },
        navigate: navigate,
        setRed: setRed
      },
    });
    if (modifyOtp.length === 6) {
      if (modifyOtp.join("") === otpValue) {
        // Cookies.remove("mhet_cnd_mobileno_otp");

      } else {
        // setError("Wrong OTP Entered");
        setWrongotp(wrongOTP + 1)
        setRed(true)
      }
    } else {
      setError("Enter valid OTP");


    }
  };
  // const handleVerify = () => {


  //   // Cookies.remove("mhet_cnd_mobileno");
  //   dispatch({
  //     type: authActions.VERIFY_MOBILENO_FOR_SIGNUP,
  //     payload: {
  //       data: { token: Cookies.get("mhet_cnd_temptoken"), mobileno: Cookies.get("mhet_cnd_mobileno"), otp: otp },
  //       navigate: navigate,
  //     },
  //   });
  //   setAttempt(attempt + 1)
  //   // navigate(`/${Cookies.get('mhet_cnd_project')}/mobileno-verification`);

  // };

  const handleResendOTP = () => {
    setOtp("");
    setResendCountdown(60);
    dispatch({
      type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      payload: {
        data: {
          mobileno: Cookies.get("mhet_cnd_mobileno"),
          // navigate: navigate,
        },
      },
    });
    setAttempt(attempt + 1)
  };

  // const inputStyle = (isFocused) => ({
  //   width: "50px",
  //   height: "52px",
  //   margin: "8px",
  //   borderRadius: "10px",
  //   border: modifyOtp.join("") === otpValue
  //     ? "1px solid #504E50"
  //     : red
  //       ? "2px solid red"
  //       : isFocused
  //         ? "5px solid #BDD8FE"
  //         : ""
  //   ,
  //   color: `${cssProperties?.color?.black}`,
  //   padding: "8px 16px 8px 16px",
  //   background: `${cssProperties?.color?.white}`,
  // });

  const inputStyle = (isFocused) => ({
    width: "38px",
    height: "40px",
    margin: "5px",
    borderRadius: "8px",
    outline: "none",
    border: modifyOtp.join("") === otpValue
      ? "1px solid #504E50"
      : red
        ? "2px solid red"
        : isFocused
          ? "5px solid #BDD8FE"
          : ""
    ,
    // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
    // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
    textAlign: "center"
  });
  const handleMobileNoVerification = () => {
    console.log(Cookies.get('mhet_cnd_mobileno'))
    dispatch({
      type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      payload: { data: { mobileno: Cookies.get('mhet_cnd_mobileno'), navigate: navigate, setResendCountdown: setResendCountdown } },
    });
  }

  const handleEdit = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: {
        show: false,
        type: "EV",
        method: "emailAndMobileVerification"
      }
    });
    dispatch({
      type: commonActions.SET_BUTTON_LOADER,
      payload: false
    })

  }
  const handleLog = () => {

  }
  return (
    <Dialog
      open={
        showModal?.show && showModal?.method === "emailAndMobileVerification"
      }
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          borderRadius: "21px 21px 10px 10px",
          background: "#FEFEFEFE",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#212121",
          textAlign: "center",
        }}
      ></DialogTitle>
      {showModal?.type === "EV" && (
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "60vh",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "left",
              }}
            >
              {" "}
              Email Verification
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#212121",
                wordWrap: "break-word",
                textAlign: "center",
              }}
            >
              Thank you for registering! We've sent a verification link to below
              email ID.
              <Typography
                mt={1}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              > <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px" }}>
                  {DecryptObjectFunction(Cookies.get('mhet_cnd_tempdata'))?.emailid} <PiPencilSimpleLineBold onClick={() => handleEdit()} style={{ color: `${cssProperties?.color?.primary}`, cursor: "pointer" }} size={20} /></Box></Typography>
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              If you haven't received the Verification link, please check your
              spam or junk folder.
              <br />
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              To re-send the link{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0F4F96",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleResendEmail}
              >
                Click here
              </span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Once your email is verified, please{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0F4F96",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleCheckEmailVerification}
              >
                Click here
              </span>
            </Typography>
          </Grid>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            This page will auto redirect to the next step. Please do not close
            or reload this page
          </Typography>
        </DialogContent>
      )}
      {showModal?.type === "EVS" && (
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Box
              component="img"
              sx={{ height: "90px", width: "auto" }}
              src={verifyImage}
            />
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "center",
              }}
            >
              Email verified successfully
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Great! Your email has been verified. Next, let’s verify your phone
              number
            </Typography>
            <Box pt={2} sx={{ width: isMobile ? "15rem" : "30rem" }}>
              <PrimaryButton handleClick={handleMobileNoVerification} text={"Verify mobile number"} />
            </Box>
          </Grid>
        </DialogContent>
      )}
      {showModal?.type === "MV" && (
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "center",
              }}
            >
              Mobile verification
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {" "}
              We have sent an OTP to your registered phone number. Enter the OTP
              below
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {" "}
              to verify your phone number.
            </Typography>
            <Typography
              mt={2}
              sx={{
                width: "145px",
                height: "20px",
                gap: "16px",
                fontWeight: "bold",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px" }}>
                <Box sx={{ display: "flex", gap: "4px" }}><Box>+91{" "}</Box>{hashMobile(DecryptObjectFunction(Cookies.get("mhet_cnd_tempdata"))?.mobileno)}</Box><PiPencilSimpleLineBold onClick={() => handleEdit()} style={{ color: `${cssProperties?.color?.primary}`, cursor: "pointer" }} size={20} /></Box>
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "16.94px",
                textAlign: "center",
              }}
            >
              {error && <small className="error">{error}</small>}
            </Typography>
            {wrongOTP >= 1 && <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "16.94px",
                textAlign: "center",
                color: "red"
              }}
            > Wrong 0TP entered ( {wrongOTP} / 5)            </Typography>}
            <Box my={2}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    style={inputStyle(focusedInput === index)}
                    onFocus={() => setFocusedInput(index)}
                    onBlur={() => setFocusedInput(null)}
                  />
                )}
              />
            </Box>
            {/* <Box
              className="otp"
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <Box my={2}>
                <OTPInput
                  inputStyle={inputStyle}
                  value={otp}
                  style={inputStyle(focusedInput === index)}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </Box>
              
            </Box> */}
            <Box pt={2} sx={{ width: isMobile ? "15rem" : "24rem" }}>
              <PrimaryButton text={"Verify"} handleClick={handleVerify} disabled={otp?.length !== 6 || (wrongOTP == 5)}
              />
            </Box>

            <Grid
              sx={{
                width: "24rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!resendCountdown > 0 && (
                <Typography
                  mt={2}
                  sx={{
                    cursor: attempt === 5 ? "not-allowed" : "pointer",
                    color: attempt === 5 ? "grey" : `${cssProperties?.color?.primary}`,
                    fontWeight: "bold",
                  }}
                  onClick={attempt === 5 ? handleLog : handleResendOTP}
                >
                  Resend OTP
                </Typography>
              )}
              {resendCountdown > 0 && (
                <Typography
                  sx={{
                    color: `${cssProperties?.color?.primary}`,
                    fontWeight: "bold",
                    fontSize: "14px"

                  }}
                  textAlign={"left"}
                  mt={2}
                >
                  RESEND OTP IN{" "}
                  {resendCountdown > 0 ? `(${resendCountdown}) SEC` : "0 SEC"}
                </Typography>
              )}
              <Typography
                mt={2}
                sx={{
                  color: `${cssProperties?.color?.primary}`,
                  fontWeight: "bold",
                  fontSize: "14px"

                }}
              >
                {attempt} / 5 &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {showModal?.type === "MVS" && (
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Box
              component="img"
              sx={{ height: "90px", width: "auto" }}
              src={verifyImage}
            />
            <Typography
              mt={2}
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24.2px",
                textAlign: "center",
              }}
            >
              Mobile number Verified successfully
            </Typography>
            <Box pt={2} sx={{ width: isMobile ? "15rem" : "30rem" }}>
              <PrimaryButton handleClick={() => navigate(`/${Cookies.get("mhet_cnd_project")}/login`)} text={"Continue To Login"} />
            </Box>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
}
