import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Modal, Typography, InputAdornment, TextField, Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { PiX } from "react-icons/pi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { InputTrimFunction, passwordValidation } from "../../utils/validations";
import authActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import verifiedIcon from "../../assets/images/icons/verified.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px',
};

export default function ChangePassword(props) {
    const { handleClose, showModal } = props;
    const [passState, setPassState] = useState(false);
    const [passState2, setPassState2] = useState(false);
    const [pass, setPass] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const Id = useSelector(
        (state) => state.authReducer.candidateTokenDetails?.candidateid)
    const [success, setSuccess] = useState(true)

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setIsLengthValid(value.length >= 8);
        setHasLowerCase(/[a-z]/.test(value));
        setHasUpperCase(/[A-Z]/.test(value));
        setHasNumber(/\d/.test(value));
        setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    };

    const handleFieldFocus = async (currentFieldName, previousFieldName) => {
        if (previousFieldName) {
            const isValid = await trigger(previousFieldName);
            if (!isValid) {
                // If the previous field is invalid, focus back on it
                const previousField = document.querySelector(
                    `input[name="${previousFieldName}"]`
                );
                if (previousField) {
                    previousField.focus();
                }
                return false; // Prevent focus on the current field
            }
        }
        return true; // Allow focus on the current field
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm({
        mode: "onChange",
    });

    // Watch oldPassword and password fields
    const oldPassword = watch("oldPassword");
    const newPassword = watch("password");

    // Re-validate new password when old password changes
    useEffect(() => {
        if (newPassword) {
            trigger("password");
        }
    }, [oldPassword, newPassword, trigger]);

    const textFieldTitleStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "16.94px",
        textAlign: "left",
        color: `${cssProperties?.color?.tertiary}`,
        paddingBottom: "4px",
    };

    const textFieldInfoStyle = {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "14.52px",
        textAlign: "left",
        color: `${cssProperties?.color?.primary2}`,
    };

    const onSubmit = (data) => {
        if (isLengthValid && hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar) {
            dispatch({
                type: authActions.CHANGE_PASSWORD,
                payload: { data: { ...data, candidateid: parseInt(Id), password: data?.password, temppassword: data?.oldPassword }, setSuccess: setSuccess }
                // payload: { candidateid: parseInt(Id), password: data?.password, temppassword: data?.oldPassword },
            });
        }
        console.log(data, "data");
    };

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {success ? <Box sx={style}>

                <Box display="flex" alignItems="center" marginBottom='24px' style={{ justifyContent: "space-between" }}>
                    <Box flexGrow={1}>
                        <Typography variant="h5" sx={{
                            color: `#504E50`, fontSize: '20px', fontWeight: '500'
                        }}>
                            Change Password
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <PiX />
                        </IconButton>
                    </Box>
                </Box>
                <Grid >
                    <Grid item sx={{ justifyContent: "center", alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            marginBottom: 1, color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            Old Password
                        </Typography>
                        <TextField
                            type={pass ? "text" : "password"}

                            id="oldPassword"
                            {...register("oldPassword", {
                                required: "Old password is required",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters",
                                },
                            })}
                            helperText={errors.oldPassword ? errors.oldPassword.message : ""}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPass(!pass)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {pass ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ justifyContent: "center", marginBottom: 1, alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            New Password
                        </Typography>
                        <TextField
                            helperText={errors.password ? errors.password.message : ""}
                            fullWidth
                            type={passState ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPassState(!passState)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {passState ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            {...register("password", {
                                required: "New password is required",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters",
                                },
                                validate: (value) =>
                                    value !== getValues("oldPassword") || "New password must be different from old password",
                            })}
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                            onChange={handlePasswordChange}
                        />
                    </Grid>
                    <Grid item sx={{ justifyContent: "center", alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            marginBottom: 1, color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            Re-type New Password
                        </Typography>
                        <TextField
                            helperText={errors.retypepassword?.message}
                            fullWidth
                            error={!!errors.retypepassword}
                            FormHelperTextProps={{
                                style: { margin: 0 },
                            }}
                            {...register("retypepassword", {
                                required: "Re-type password is required",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters",
                                },
                                validate: (value) =>
                                    value === watch("password") ||
                                    "Passwords do not match",
                            })}
                            onFocus={() =>
                                handleFieldFocus("retypepassword", "password")
                            }
                            variant="outlined"
                            type={passState2 ? "text" : "password"}
                            onInput={InputTrimFunction}
                            // onKeyUp={handlePasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPassState2(!passState2)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {passState2 ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                        />
                    </Grid>
                    <Grid container pt={3} pb={2} spacing={2} sx={{ fontSize: "14px", fontWeight: 400, color: "#00000099" }}>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: isLengthValid ? "green" : errors.password || !isLengthValid ? "red" : "#00000099" }}>
                            <IoCheckmarkCircleOutline size={18} color={isLengthValid ? "green" : errors.password || !isLengthValid ? "red" : "#00000099"} />
                            Password should be minimum 8 characters long
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasLowerCase ? "green" : errors.password || !hasLowerCase ? "red" : "#00000099" }}>
                            <IoCheckmarkCircleOutline size={18} color={hasLowerCase ? "green" : errors.password || !hasLowerCase ? "red" : "#00000099"} />
                            One lowercase letter
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasUpperCase ? "green" : errors.password || !hasUpperCase ? "red" : "#00000099" }}>
                            <IoCheckmarkCircleOutline size={18} color={hasUpperCase ? "green" : errors.password || !hasUpperCase ? "red" : "#00000099"} />
                            One uppercase letter
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasNumber ? "green" : errors.password || !hasNumber ? "red" : "#00000099" }}>
                            <IoCheckmarkCircleOutline size={18} color={hasNumber ? "green" : errors.password || !hasNumber ? "red" : "#00000099"} />
                            One number
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasSpecialChar ? "green" : errors.password || !hasSpecialChar ? "red" : "#00000099" }}>
                            <IoCheckmarkCircleOutline size={18} color={hasSpecialChar ? "green" : errors.password || !hasSpecialChar ? "red" : "#00000099"} />
                            One special character
                        </Grid>
                    </Grid>

                    <Button variant="contained" sx={{
                        background: "rgba(15, 79, 150, 1)",
                        color: "#D7E2E9",
                        padding: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: "6px",
                        minHeight: "40px",
                        lineHeight: "16.4px",
                        "&:hover": {
                            backgroundColor: `${cssProperties?.color?.buttonHover}`,
                        },

                    }} color="primary" fullWidth
                        onClick={handleSubmit(onSubmit)}>
                        Update Password
                    </Button>
                </Grid>

            </Box> :
                <Box sx={style}>
                    <Grid
                        container
                        spacing={2}
                        p={3}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                width: "100%",
                                marginBottom: 2,
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={verifiedIcon}
                                alt="verified"
                                style={{
                                    width: "96px",
                                    height: "91.73px",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Password Change Successfully
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                Your password has been updated successfully. Please click here to log in<br />
                                with your new password
                            </Typography>
                        </Grid>

                        <Grid item xs={12} lg={3} sx={{
                            width: "100%", display: "flex",
                            justifyContent: "center"
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    padding: "10px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    width: "500px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: `${cssProperties?.color?.buttonHover}`,
                                    },

                                }}
                                onClick={() => {
                                    window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
                                    Cookies.remove("mhet_cnd_token");
                                }}
                            >
                                Go to Login Page
                            </Button>
                        </Grid>
                    </Grid></Box>
            }
        </Modal>
    );
}
