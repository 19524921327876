import React, { useEffect, useState } from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import authActions from "../../redux/auth/actions";
import { FaCheckCircle } from "react-icons/fa";
import { cssProperties } from "../../utils/commonCssProperties";

export default function Tabs(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  console.log('coursename-for tab--',coursename);
  const { activeTab, courseRegistrationData ,setIndex} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filledForms, setFilledForms] = useState('');
  const { courseStatus, courseFormDetails } = useSelector((state) => state?.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const ispaid = useSelector((state) => state.candidatesReducer?.ispaid?.ispaid);


  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  }, []);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_COURSE_STATUS, payload: { candidateid, courseid } })
      dispatch({ type: candidatesActions.GET_IS_PAID, payload: { candidateid, courseid } })
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseStatus) {
      console.log("courseStatus---", courseStatus);
      // const filledForms = courseStatus.profile_status === 1 ? 1 : courseStatus.address_status === 1 ? 2 : courseStatus.reservation_status === 1 ? 3 : courseStatus.academic_status === 1 ? 4 : courseStatus.exampreference_status === 1 ? 5 : 0
      const filledForms = courseStatus.exampreference_status === 1 ? 5 : courseStatus.academic_status === 1 ? 4 : courseStatus.reservation_status === 1 ? 3 : courseStatus.address_status === 1 ? 2 : courseStatus.profile_status === 1 ? 1 : null
      setFilledForms(filledForms);
      console.log(filledForms,'filledForms');
    }



  }, [courseStatus]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
    }
  }, [courseid]);
  const status = courseFormDetails[0]?.status;
  console.log(status,'status');
  

  //   const StyledStepLabel = styled(StepLabel)(({ theme, active }) => ({
  //     "&.MuiStepLabel-root": {
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       textAlign: "center",
  //       background: active ? "transparent" : "transparent",
  //       color: active ? "#ffffff" : "#0F4F96",
  //       padding: theme.spacing(1),
  //       borderRadius: theme.shape.borderRadius,
  //     },
  //     "& .MuiStepLabel-iconContainer": {
  //       marginRight: theme.spacing(1),
  //     },
  //   }));
  //   const handleTabClick = (index, to) => {

  //     if (ispaid == 1 || ispaid != 1) {
  //       navigate(to, {
  //         state: {
  //           courseid: courseid,
  //         },
  //       });

  //     }
  //     else if (index <= filledForms && to) {
  //       navigate(to, {
  //         state: {
  //           courseid: courseid,
  //         },
  //       });
  //     }
  //   };

  //   return (
  //     <Grid
  //       sx={{
  //         height: "auto",
  //         backgroundColor: "#0F4F9633",
  //         margin: "10px 0px",
  //         padding: "15px 15px",
  //         borderRadius: "8px",
  //         overflowX: "auto",
  //         width: "100%",
  //         whiteSpace: "nowrap",
  //         "&::-webkit-scrollbar": {
  //           height: "4px",
  //         },
  //         "&::-webkit-scrollbar-thumb": {
  //           backgroundColor: "rgba(0, 0, 0, 0.2)",
  //         },
  //       }}
  //     >
  //       <Stepper
  //         activeStep={ispaid == 1 ? 7 : activeTab === 6 ? activeTab : activeTab === 7 ? activeTab : filledForms}
  //         orientation={isMobile ? "vertical" : "horizontal"}
  //       >
  //         {courseRegistrationData.map((item, index) => (
  //           <Step key={index} sx={{ pr: 10 }}>
  //             <StyledStepLabel onClick={() => handleTabClick(index, item.to)}
  //               sx={{
  //                 cursor: ispaid == 1 ? "pointer" : index <= filledForms ? "pointer" : "default",
  //                 '& .MuiStepLabel-label': {
  //                   cursor: ispaid == 1 ? "pointer" : index <= filledForms ? "pointer" : "default",
  //                 }
  //               }}
  //               icon={item.icon}
  //             >
  //               {item.title}
  //             </StyledStepLabel>
  //           </Step>
  //         ))}
  //       </Stepper>
  //     </Grid>
  //   );
  // }

  
  const StyledStepLabel = styled(StepLabel)(({ theme, clickable, completed, index, isCheck }) => ({
    "&.MuiStepLabel-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: (clickable || completed || ispaid === '1' ) ? "green" : "black",
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      transition: "background-color 0.3s",
    },
    "& .MuiStepLabel-iconContainer": {
      marginRight: theme.spacing(1),
      background: completed || ispaid === '1'
        ? "white" : clickable || index === activeTab ?
        `${cssProperties?.backgroundcolor?.primary}` : "white",
      borderRadius: "50%",
      padding: theme.spacing(0.5),
      transition: "background-color 0.3s",
    },
    "& .MuiStepLabel-label": {
      color: completed ? "green" : "inherit",
      cursor: ((clickable && courseStatus?.profile_status < 7) ||  ( activeTab === index && ispaid === '1') || (ispaid === '1' ? !completed : completed)) ? "pointer" :  courseStatus?.profile_status >= 7 ? "not-allowed" : "default",
    },
  }));
  

  
const handleTabClick = (index, to) => {
  console.log("clicked");

 dispatch({
   type: candidatesActions.GET_COURSE_FORM_DETAILS,
   payload: { courseid: courseid },
 });
 setIndex(index);
 navigate(to, {
   state: {
     courseid: courseid,
     coursename: coursename
   },
 });
};

  

  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#0F4F9633",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
        overflowX: "auto",
        width: "100%",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": {
          height: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stepper
        activeStep={ispaid === 1 ? 7 : activeTab === 6 ? activeTab : activeTab === 7 ? activeTab : filledForms}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {courseRegistrationData.map((item, index) => {
          const clickable = ispaid === 1 || index <= filledForms;
          const completed = status >= index + 1;
          const isCheck = ispaid === 1
          const navigationclickable = index <= filledForms;

          return (
            <Step key={index} sx={{ pr: 10 }} >
              <StyledStepLabel
                onClick={() => 
                  (clickable && courseStatus?.profile_status < 7) && handleTabClick(index, item.to)}
                clickable={clickable}
                completed={completed}
                isCheck={isCheck}
                index={index}
                sx={{
                  cursor: ((clickable && courseStatus?.profile_status < 7) ||  ( activeTab === index && ispaid === '1') || (ispaid === '1' ? !completed : completed)) ? "pointer" :  courseStatus?.profile_status >= 7 ? "not-allowed" : "default",
                  '& .MuiStepLabel-label': {
                    color: completed || ispaid === '1'? 'green' : index === activeTab ? "#001F3FB2" : 'inherit',
                    cursor: ((clickable && courseStatus?.profile_status < 7) || ( activeTab === index && ispaid === '1') || (ispaid === '1' ? !completed : completed) )? "pointer" : courseStatus?.profile_status >= 7 ? "not-allowed" : "default",
                    fontWeight: index === activeTab ? "bold" : "normal",
                  }
                }}
                icon={completed || ispaid === '1' ? <FaCheckCircle size={28} sx={{ color: "green"}} /> : item.icon}
              >
                {item.title}
              </StyledStepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
  );
}
// import React from "react";
// import {
//   Grid,
//   Stepper,
//   Step,
//   StepLabel,
//   useMediaQuery,
//   useTheme,
//   styled,
// } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";

// export default function Tabs(props) {
//   const navigate = useNavigate();
//   const { courseid, coursename } = useLocation().state;
//   const { activeTab, courseRegistrationData } = props;
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



//   const StyledStepLabel = styled(StepLabel)(({ theme, active }) => ({
//     "&.MuiStepLabel-root": {
//       fontweight: active ? "500" : "transparent",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       textAlign: "center",
//       background: active ? "#0F4F96" : "transparent",
//       color: active ? "#0F4F96" : "#0F4F96",
//       padding: theme.spacing(1),
//       borderRadius: theme.shape.borderRadius,
//     },
//     "& .MuiStepLabel-iconContainer": {
//       marginRight: theme.spacing(1),
//     },
//   }));
//   return (
//     <Grid
//       sx={{
//         height: "auto",
//         backgroundColor: "#0F4F9633",
//         margin: "10px 0px",
//         padding: "15px 15px",
//         borderRadius: "8px",
//         overflowX: "auto",
//         width: "100%",
//         whiteSpace: "nowrap",
//         "&::-webkit-scrollbar": {
//           height: "4px",
//         },
//         "&::-webkit-scrollbar-thumb": {
//           backgroundColor: "rgba(0, 0, 0, 0.2)",
//         },
//       }}
//     >
//       <Stepper
//         activeStep={activeTab}
//         orientation={isMobile ? "vertical" : "horizontal"}
//       >
//         {courseRegistrationData?.map((item, index) => (

//           <Step key={index}>
//             {console.log(activeTab, "activeTab",index,"index")}

//             <StyledStepLabel
//               sx={{ cursor: "pointer", color: '#212121' }}
//               onClick={() => (item.to ? navigate(item.to, { state: { courseid: courseid, coursename: coursename } }) : null)}
//               icon={item.icon}
//             >
//               {item.title}
//             </StyledStepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Grid>
//   );
// }
// import React from "react";
// import {
//   Grid,
//   Stepper,
//   Step,
//   StepLabel,
//   useMediaQuery,
//   useTheme,
//   styled,
// } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";

// export default function Tabs(props) {
//   const navigate = useNavigate();
//   const { courseid, coursename } = useLocation().state;
//   const { activeTab, courseRegistrationData } = props;
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const StyledStepLabel = styled(StepLabel)(({ theme, active }) => ({
//     "&.MuiStepLabel-root": {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       textAlign: "center",
//       background: active ? "transparent" : "transparent",
//       color: active ? "#ffffff" : "#0F4F96",
//       padding: theme.spacing(1),
//       borderRadius: theme.shape.borderRadius,
//     },
//     "& .MuiStepLabel-iconContainer": {
//       marginRight: theme.spacing(1),
//     },
//   }));

//   return (
//     <Grid
//       sx={{
//         height: "auto",
//         backgroundColor: "#0F4F9633",
//         margin: "10px 0px",
//         padding: "15px 15px",
//         borderRadius: "8px",
//         overflowX: "auto",
//         width: "100%",
//         whiteSpace: "nowrap",
//         "&::-webkit-scrollbar": {
//           height: "4px",
//         },
//         "&::-webkit-scrollbar-thumb": {
//           backgroundColor: "rgba(0, 0, 0, 0.2)",
//         },
//       }}
//     >
//       <Stepper
//         activeStep={activeTab}
//         orientation={isMobile ? "vertical" : "horizontal"}
//       >
//         {courseRegistrationData?.map((item, index) => {
//           const isActive = activeTab === index;

//           if (isActive) {
//             console.log(`Active Tab: ${activeTab}, Index: ${index}`);
//           }

//           return (
//             <Step key={index}>
//               <StyledStepLabel
//                 sx={{
//                   cursor: "pointer", fontWeight: activeTab === index ? "bolder" : "normal",
//                 }}
//                 onClick={() => (item.to ? navigate(item.to, { state: { courseid, coursename } }) : null)}
//                 icon={item.icon}
//                 active={isActive}
//               >
//                 {item.title}
//               </StyledStepLabel>
//             </Step>
//           );
//         })}
//       </Stepper>
//     </Grid>
//   );
// }
