import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  AddressValidationFirstField,
  AlphabetsValidation,
  AddressValidation,
  pincodeValidation,
  pincodeNumberValidation,
  NumbersValidation,
  MobileNumberValidation,
  MobileNumberFirstNumberValidation,
  AlphabetsandNumbersValidation,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import OtherDetailsForm from "./otherDetailsForm";

export default function AddressForm({ preprocessFormData }) {
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { state, districtForReg, talukaForReg, villageForReg } = useSelector(
    (state) => state.masterReducer
  );
  console.log("districtForReg--", districtForReg);

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { addressDetails, pincode, pincodeCorrespondence } = useSelector(
    (state) => state.candidatesReducer
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // const handleIsSameAddressChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setIsSameAddress(isChecked);

  //   if (!isChecked) {
  //     const fields = [
  //       "caddressline1",
  //       "caddressline2",
  //       "caddressline3",
  //       "cpincode",
  //       "cstateid",
  //       "cdistrictid",
  //       "ctalukaid",
  //       "cvillageid",
  //     ];

  //     fields.forEach((field) => {
  //       setValue(field, "");
  //     });
  //   } else {
  //     dispatch({
  //       type: masterActions.SET_DISTRICT_FOR_REGISTRATION,
  //       payload: {
  //         ...districtForReg,
  //         corespondence: [...districtForReg?.parmanent],
  //       },
  //     });
  //     dispatch({
  //       type: masterActions.SET_TALUKA_FOR_REGISTRATION,
  //       payload: {
  //         ...talukaForReg,
  //         corespondence: [...talukaForReg?.parmanent],
  //       },
  //     });
  //     dispatch({
  //       type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
  //       payload: {
  //         ...villageForReg,
  //         corespondence: [...villageForReg?.parmanent],
  //       },
  //     });
  //   }
  // };

  const handleEdit = () => {
    setIsEditMode(true);
    if (isSameAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleIsSameAddressChange = (e) => {
    const isChecked = e.target.checked;
    setIsSameAddress(isChecked);
    setDisabled(isChecked);

    if (!isChecked) {
      // const fields = [
      //   "caddressline1",
      //   "caddressline2",
      //   "caddressline3",
      //   "cpincode",
      //   "cstateid",
      //   "cdistrictid",
      //   "ctalukaid",
      //   "cvillageid",
      // ];
      // fields.forEach((field) => {
      //   setValue(field, "");
      // });
    }
    // } else {
    //   // Safely check if permanent exists and is iterable
    //   if (Array.isArray(districtForReg?.permanent)) {
    //     dispatch({
    //       type: masterActions.SET_DISTRICT_FOR_REGISTRATION,
    //       payload: {
    //         ...districtForReg,
    //         correspondence: [...districtForReg.permanent], // Safely spread the array
    //       },
    //     });
    //   } else {
    //     console.error('districtForReg.permanent is not an array:', districtForReg?.permanent);
    //   }

    //   if (Array.isArray(talukaForReg?.permanent)) {
    //     dispatch({
    //       type: masterActions.SET_TALUKA_FOR_REGISTRATION,
    //       payload: {
    //         ...talukaForReg,
    //         correspondence: [...talukaForReg.permanent],
    //       },
    //     });
    //   } else {
    //     console.error('talukaForReg.permanent is not an array:', talukaForReg?.permanent);
    //   }

    //   if (Array.isArray(villageForReg?.permanent)) {
    //     dispatch({
    //       type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
    //       payload: {
    //         ...villageForReg,
    //         correspondence: [...villageForReg.permanent],
    //       },
    //     });
    //   } else {
    //     console.error('villageForReg.permanent is not an array:', villageForReg?.permanent);
    //   }
    // }
  };

  useEffect(() => {
    if (isSameAddress) {
      setValue("caddressline1", watch("paddressline1"));
      setValue("caddressline2", watch("paddressline2"));
      setValue("caddressline3", watch("paddressline3"));
      setValue("cpincode", watch("ppincode"));
      setValue("cstateid", watch("pstateid"));
      setValue("cdistrictid", watch("pdistrictid"));
      setValue("ctalukaid", watch("ptalukaid"));
      setValue("cvillageid", watch("pvillageid"));
      trigger([
        "caddressline1",
        "caddressline2",
        "caddressline3",
        "cpincode",
        "cstateid",
        "cdistrictid",
        "ctalukaid",
        "cvillageid",
      ]);
    }
  }, [
    watch("paddressline1"),
    watch("paddressline2"),
    watch("paddressline3"),
    watch("ppincode"),
    watch("pstateid"),
    watch("pdistrictid"),
    watch("ptalukaid"),
    watch("pvillageid"),
    isSameAddress,
  ]);

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };

  const candidateaddressdraftid = addressDetails[0]?.candidateaddressdraftid;

  const candidateaddressid = addressDetails[0]?.candidateaddressid;

  useEffect(() => {
    setTimeout(() => {
      if (addressDetails.length > 0) {
        if (addressDetails[0].status === 2) {
          console.log(addressDetails[0].status, "status");
          setIsEditMode(false);
        } else {
          console.log(addressDetails[0].status, "status false");
          setIsEditMode(true);
        }
        reset({ ...addressDetails[0], phoneno: addressDetails[0]?.phoneno == 'null' ? "" 
          : addressDetails[0]?.phoneno, stdcode: addressDetails[0]?.stdcode == 'null' ? "" : addressDetails[0]?.stdcode });
        console.log('addressDetails[0]--', addressDetails[0])

        if (addressDetails[0]?.sameascommunication === "1") {
          setIsSameAddress(true);
        }
      }
    }, 1000);
  }, [addressDetails]);

  const dispatch = useDispatch();

  const handleDraft = (getValues) => {
    const values = preprocessFormData(getValues());
    let operation = candidateaddressdraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_ADDRESS_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: candidateaddressid ? 2 : 1,
          operation: operation,
          sameascommunication: isSameAddress ? "1" : "0",
          candidateid: parseInt(candidateid),
        },
      },
    });

    setTimeout(() => {

      dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });

    }, 1000);

    setIsEditMode(false)
  };

  const handleSave = (data) => {
    let operation = candidateaddressid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_ADDRESS_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
          sameascommunication: isSameAddress ? "1" : "0",
        },
        navigate: navigate,
      },
    });
    dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  const watchPermanentFields = watch([
    "paddressline1",
    "paddressline2",
    "paddressline3",
    "ppincode",
    "pstateid",
    "pdistrictid",
    "ptalukaid",
    "pvillageid",
  ]);

  // const handleCheckboxChange = (e) => {
  //   const isChecked = e.target.checked;
  //   const fields = [
  //     "caddressline1",
  //     "caddressline2",
  //     "caddressline3",
  //     "cpincode",
  //     "cstateid",
  //     "cdistrictid",
  //     "ctalukaid",
  //     "cvillageid",
  //   ];

  //   fields.forEach((field, index) => {
  //     const correspondingField = Object.keys(watchPermanentFields)[index];
  //     setValue(
  //       field,
  //       isChecked ? watchPermanentFields[correspondingField] : ""
  //     );
  //   });
  // };

  const handlePincodePermanent = (e) => {
    const value = e.target.value;
    setValue("ppincode", value);
    setValue("pstateid", "");
    setValue("pdistrictid", "");
    setValue("ptalukaid", "");
    setValue("pvillageid", "");
    setError("pstateid");
    setError("pdistrictid");
    setError("ptalukaid");
    setError("pvillageid");
    trigger(["pstateid", "pdistrictid", "ptalukaid", "pvillageid"]);
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE,
        payload: { value: value },
      });
      clearErrors("ppincode");
      // setValue("ctalukaid", watch("ptalukaid"));
    } else {
      setError("ppincode", {
        message: "Invalid input. Pincode must be 6 digits long.",
      });
    }
  };

  const handlePincodeCorrspondance = (e) => {
    const value = e.target.value;
    setValue("cpincode", value);
    setValue("cstateid", "");
    setValue("cdistrictid", "");
    setValue("ctalukaid", "");
    setValue("cvillageid", "");
    setError("cstateid");
    setError("cdistrictid");
    setError("ctalukaid");
    setError("cvillageid");
    trigger(["cstateid", "cdistrictid", "ctalukaid", "cvillageid"]);
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE_CORRESPONDENCE,
        payload: { value: value },
      });
      clearErrors("cpincode");
    } else {
      setError("cpincode", {
        message: "Invalid input. Pincode must be 6 digits long.",
      });
    }
  };

  useEffect(() => {
    if (pincode.length > 0 && pincode[0]) {
      setValue("pstateid", pincode[0]?.stateid);
      setValue("pdistrictid", pincode[0]?.districtid);
      setValue("ptalukaid", pincode[0]?.talukaid);
      trigger(["pstateid", "pdistrictid", "ptalukaid", "pvillageid"]);
      // pincode[0]?.stateid ? clearErrors('pstateid') : setError('pstateid');
      // pincode[0]?.districtid ? clearErrors('pdistrictid') : setError('pdistrictid');
      // pincode[0]?.talukaid ? clearErrors('ptalukaid') : setError('ptalukaid');
    }
    // else {
    // setValue("pstateid", '');
    // setValue("pdistrictid", '');
    // setValue("ptalukaid", '');
    // setValue("pvillageid", '');
    // if(errors?.){
    // setError('pstateid');
    // setError('pdistrictid');
    // setError('ptalukaid');
    // setError('pvillageid')
    // }
    // }
  }, [pincode]);

  useEffect(() => {
    if (pincodeCorrespondence.length > 0 && pincodeCorrespondence[0]) {
      setValue("cstateid", pincodeCorrespondence[0]?.stateid);
      setValue("cdistrictid", pincodeCorrespondence[0]?.districtid);
      setValue("ctalukaid", pincodeCorrespondence[0]?.talukaid);
      trigger(["cstateid", "cdistrictid", "ctalukaid", "cvillageid"]);
    }
  }, [pincodeCorrespondence]);

  const handleBack = () => {
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
    );
  };


  console.log(addressDetails, "addressDetails");

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Grid>
        <Typography
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Permanent Address
        </Typography>
        <Grid pt={1} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={!isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 1
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Door no,street"
              {...register("paddressline1", {
                required: "Address is required",
                validate: AddressValidationFirstField,
              })}
              error={!!errors.paddressline1}
              helperText={errors.paddressline1?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("paddressline1")?.trim(),
              }}
            />
            {/* {errors.paddressline1 && (
              <small className="text-danger">
                {errors.paddressline1.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={!isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 2
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Locality"
              {...register("paddressline2", {
                required: "Address is required",
                validate: AddressValidation,
              })}
              error={!!errors.paddressline2}
              helperText={errors.paddressline2?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsandNumbersValidation}
              InputLabelProps={{
                shrink: !!watch("paddressline2")?.trim(),
              }}
            />
            {/* {errors.paddressline2 && (
              <small className="text-danger">
                {errors.paddressline2.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={!isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 3
                  {/* <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span> */}
                </span>
              }
              placeholder="Locality"
              {...register("paddressline3", {
                // required: "Address is required",
                validate: AddressValidation,
              })}
              // error={!!errors.paddressline3}
              // helperText={errors.paddressline3?.message}
              // FormHelperTextProps={{
              //   style: { margin: 0 },
              // }}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                shrink: !!watch("paddressline3")?.trim(),
              }}
            />
            {/* {errors.paddressline3 && (
              <small className="text-danger">
                {errors.paddressline3.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              disabled={!isEditMode}
              fullWidth
              type="text"
              variant="outlined"
              label={
                <span>
                  Pincode
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the pincode"
              {...register("ppincode", {
                required: "Pincode is required",
                validate: pincodeValidation,
              })}
              onFocus={handleFocus}
              onChange={handlePincodePermanent}
              onInput={pincodeNumberValidation}
              error={!!errors.ppincode}
              helperText={errors.ppincode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ppincode")?.trim(),
              }}
            />
            {/* {errors.ppincode && (
              <small className="text-danger">{errors.ppincode.message}</small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pstateid}>
              <InputLabel
                shrink={!!watch("pstateid") || watch("pstateid") === 0}
                htmlFor="pstateid"
              >
                State{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={!isEditMode}
                id="pstateid"
                label="State"
                // value={getValues("pstateid")}
                {...register("pstateid", {
                  required: "state is required",
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("pstateid", stateid);
                  setValue("pdistrictid", "");
                  setValue("ptalukaid", "");
                  setValue("pvillageid", "");
                  dispatch({
                    type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
                    payload: { stateid: stateid, type: "parmanent" },
                  });
                  dispatch({
                    type: masterActions.SET_TALUKA_FOR_REGISTRATION,
                    payload: { ...talukaForReg, parmanent: [] },
                  });
                  dispatch({
                    type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
                    payload: { ...villageForReg, parmanent: [] },
                  });
                  trigger([
                    "pstateid",
                    "pdistrictid",
                    "ptalukaid",
                    "pvillageid",
                  ]);
                }}
                value={watch("pstateid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.pstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pdistrictid}>
              <InputLabel
                shrink={!!watch("pdistrictid") || watch("pdistrictid") === 0}
                htmlFor="pdistrictid"
              >
                District{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={!isEditMode}
                id="pdistrictid"
                label="District"
                // value={getValues("pdistrictid")}
                value={watch("pdistrictid") ?? ""}
                {...register("pdistrictid", {
                  required: "District is required",
                })}
                onChange={(e) => {
                  const districtid = e.target.value;
                  setValue("pdistrictid", districtid);
                  dispatch({
                    type: masterActions.GET_TALUKA_FOR_REGISTRATION,
                    payload: { districtid: districtid, type: "parmanent" },
                  });
                  dispatch({
                    type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
                    payload: { ...villageForReg, parmanent: [] },
                  });
                  // trigger("pdistrictid");
                  setValue("ptalukaid", "");
                  setValue("pvillageid", "");
                  // setError('ptalukaid')
                  // setError('pvillageid')
                  trigger(["pdistrictid", "ptalukaid", "pvillageid"]);
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {districtForReg?.parmanent?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.pdistrictid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pdistrictid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.ptalukaid}>
              <InputLabel
                shrink={!!watch("ptalukaid") || watch("ptalukaid") === 0}
                htmlFor="ptalukaid"
              >
                Taluka{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={!isEditMode}
                id="ptalukaid"
                label="Taluka"
                value={watch("ptalukaid") ?? ""}
                // value={getValues("ptalukaid")}
                aria-label="Default select example"
                {...register("ptalukaid", {
                  required: "Taluka is required",
                })}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ptalukaid", talukaid);
                  dispatch({
                    type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
                    payload: { talukaid: talukaid, type: "parmanent" },
                  });
                  // trigger("ptalukaid");
                  setValue("pvillageid", "");
                  setError("pvillageid");
                  trigger(["ptalukaid", "pvillageid"]);
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {talukaForReg?.parmanent?.map((val, i) => (
                  <MenuItem value={val?.talukaid} key={i}>
                    {val?.taluka}
                  </MenuItem>
                ))}
              </Select>
              {errors.ptalukaid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ptalukaid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.pvillageid}>
              <InputLabel
                shrink={!!watch("pvillageid") || watch("pvillageid") === 0}
                htmlFor="pvillageid"
              >
                Village{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={!isEditMode}
                id="pvillageid"
                label="Village"
                value={watch("pvillageid") ?? ""}
                // value={getValues("pvillageid")}
                {...register("pvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("pvillageid", villageid);
                  // trigger("pvillageid");
                  trigger(["pvillageid"]);
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {villageForReg?.parmanent?.map((val, i) => (
                  <MenuItem value={val?.villageid} key={i}>
                    {val?.village}
                  </MenuItem>
                ))}
              </Select>
              {errors.pvillageid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pvillageid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={!isEditMode}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              // disabled={disabled}
              fullWidth
              variant="outlined"
              type="tel"
              label={
                <span>
                  Std Code
                  {/* <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span> */}
                </span>
              }
              placeholder="Enter the std code"
              {...register("stdcode", {
                // required: "Std code is required",
                maxLength: {
                  value: 8,
                  message: "Enter valid std code",
                },
              })}
              error={!!errors.stdcode}
              helperText={errors.stdcode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={NumbersValidation}
              maxLength={8}
              InputLabelProps={{
                shrink: !!watch("stdcode")?.trim(),
              }}
            />
            {/* {errors.stdcode && (
                <small className="text-danger">{errors.stdcode.message}</small>
              )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={!isEditMode}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                pattern: "[0-9]*",
                maxLength: 10,
              }}
              fullWidth
              variant="outlined"
              label={
                <span>
                  Phone Number/Mobile Number
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the phone number/mobile number"
              {...register("phoneno", {
                required: "Phone number/mobile number is required",
                minLength: {
                  value: 10,
                  message: "Enter valid phone number/mobile number",
                },
                validate: MobileNumberFirstNumberValidation,
              })}
              error={!!errors.phoneno}
              helperText={errors.phoneno?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={MobileNumberValidation}
              type="tel"
              maxLength={10}
              InputLabelProps={{
                shrink: !!watch("phoneno")?.trim(),
              }}
            />
            {/* {errors.phoneno && (
                <small className="text-danger">{errors.phoneno.message}</small>
              )} */}
          </Grid>
        </Grid>

        <Typography
          mt={1}
          variant="h4"
          sx={{
            textWrap: "wrap",
            fontSize: 20,
            fontWeight: 500,
            color: "#212121",
          }}
        >
          Correspondence Address
        </Typography>
        <FormControlLabel
          disabled={!isEditMode}
          control={
            <Checkbox
              style={{
                fontSize: 18,
                color: isSameAddress ? "#06C270" : "inherit",
              }}
              checked={isSameAddress}
              onChange={handleIsSameAddressChange}
              onClick={() => setDisabled(false)}
            />
          }
          label={
            <span className="error" style={{ color: "#666666" }}>
              Same as permanent address{" "}
            </span>
          }
        />
        <Grid pt={1} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={disabled || !isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 1
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Door no,street"
              {...register("caddressline1", {
                required: "Address is required",
                validate: AddressValidationFirstField,
              })}
              error={!!errors.caddressline1}
              helperText={errors.caddressline1?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("caddressline1")?.trim(),
              }}
            />
            {/* {errors.caddressline1 && (
              <small className="text-danger">
                {errors.caddressline1.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={disabled || !isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 2
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Locality"
              {...register("caddressline2", {
                required: "Address is required",
                validate: AddressValidation,
              })}
              error={!!errors.caddressline2}
              helperText={errors.caddressline2?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsandNumbersValidation}
              InputLabelProps={{
                shrink: !!watch("caddressline2")?.trim(),
              }}
            />
            {/* {errors.caddressline2 && (
              <small className="text-danger">
                {errors.caddressline2.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
                maxLength: 50,
              }}
              disabled={disabled || !isEditMode}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span>
                  Address line 3
                  {/* <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span> */}
                </span>
              }
              placeholder="Locality"
              {...register("caddressline3", {
                // required: "Address is required",
                validate: AddressValidation,
              })}
              // error={!!errors.caddressline3}
              // helperText={errors.caddressline3?.message}
              // FormHelperTextProps={{
              //   style: { margin: 0 },
              // }}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                shrink: !!watch("caddressline3")?.trim(),
              }}
            />
            {/* {errors.caddressline3 && (
              <small className="text-danger">
                {errors.caddressline3.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              disabled={disabled || !isEditMode}
              fullWidth
              type="text"
              variant="outlined"
              label={
                <span>
                  Pincode
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the pincode"
              {...register("cpincode", {
                required: "Pincode is required",
                validate: pincodeValidation,
              })}
              onFocus={handleFocus}
              onChange={handlePincodeCorrspondance}
              onInput={pincodeNumberValidation}
              error={!!errors.cpincode}
              helperText={errors.cpincode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("cpincode")?.trim(),
              }}
            />
            {/* {errors.ppincode && (
              <small className="text-danger">{errors.ppincode.message}</small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.cstateid}>
              <InputLabel
                shrink={!!watch("cstateid") || watch("cstateid") === 0}
                htmlFor="cstateid"
              >
                State{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled || !isEditMode}
                id="cstateid"
                label="State"
                // value={getValues("pstateid")}
                {...register("cstateid", {
                  required: "state is required",
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("cstateid", stateid);
                  dispatch({
                    type: masterActions.GET_DISTRICT_FOR_REGISTRATION,
                    payload: { stateid: stateid, type: "corespondence" },
                  });
                  dispatch({
                    type: masterActions.SET_TALUKA_FOR_REGISTRATION,
                    payload: { ...talukaForReg, corespondence: [] },
                  });
                  dispatch({
                    type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
                    payload: { ...villageForReg, corespondence: [] },
                  });
                  // trigger("cstateid");
                  setValue("cdistrictid", "");
                  setValue("ctalukaid", "");
                  setValue("cvillageid", "");
                  trigger([
                    "cstateid",
                    "cdistrictid",
                    "ctalukaid",
                    "cvillageid",
                  ]);
                }}
                value={watch("cstateid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.cstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.cdistrictid}>
              <InputLabel
                shrink={!!watch("cdistrictid") || watch("cdistrictid") === 0}
                htmlFor="cdistrictid"
              >
                District{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled || !isEditMode}
                id="cdistrictid"
                label="District"
                // value={getValues("cdistrictid")}
                {...register("cdistrictid", {
                  required: "District is required",
                })}
                onChange={(e) => {
                  const districtid = e.target.value;
                  setValue("cdistrictid", districtid);
                  dispatch({
                    type: masterActions.GET_TALUKA_FOR_REGISTRATION,
                    payload: { districtid: districtid, type: "corespondence" },
                  });
                  // dispatch({
                  //   type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
                  //   payload: { ...villageForReg, parmanent: [] },
                  // });
                  setValue("ctalukaid", "");
                  setValue("cvillageid", "");
                  trigger(["cdistrictid", "ctalukaid", "cvillageid"]);
                }}
                value={watch("cdistrictid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {isSameAddress
                  ? districtForReg?.parmanent?.map((val, i) => (
                    <MenuItem value={val?.districtid} key={i}>
                      {val?.district}
                    </MenuItem>
                  ))
                  : districtForReg?.corespondence?.map((val, i) => (
                    <MenuItem value={val?.districtid} key={i}>
                      {val?.district}
                    </MenuItem>
                  ))}
              </Select>
              {errors.cdistrictid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cdistrictid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.ctalukaid}>
              <InputLabel
                shrink={!!watch("ctalukaid") || watch("ctalukaid") === 0}
                htmlFor="ctalukaid"
              >
                Taluka{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled || !isEditMode}
                id="ctalukaid"
                label="Taluka"
                // value={getValues("ptalukaid")}
                aria-label="Default select example"
                {...register("ctalukaid", {
                  required: "Taluka is required",
                })}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ctalukaid", talukaid);
                  dispatch({
                    type: masterActions.GET_VILLAGE_FOR_REGISTRATION,
                    payload: { talukaid: talukaid, type: "corespondence" },
                  });
                  // trigger("ctalukaid");
                  setValue("cvillageid", "");
                  setError("cvillageid");
                  trigger(["ctalukaid", "cvillageid"]);
                }}
                value={watch("ctalukaid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {isSameAddress
                  ? talukaForReg?.parmanent?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))
                  : talukaForReg?.corespondence?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
              </Select>
              {errors.ctalukaid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ctalukaid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.cvillageid}>
              <InputLabel
                shrink={!!watch("cvillageid") || watch("cvillageid") === 0}
                htmlFor="cvillageid"
              >
                Village{" "}
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select MenuProps={{
                disableScrollLock: true,
              }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                disabled={disabled || !isEditMode}
                id="cvillageid"
                label="Village"
                // value={getValues("pvillageid")}
                {...register("cvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("cvillageid", villageid);
                  trigger(["cvillageid"]);
                  // trigger("cvillageid");
                }}
                value={watch("cvillageid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {isSameAddress
                  ? villageForReg?.parmanent?.map((val, i) => (
                    <MenuItem value={val?.villageid} key={i}>
                      {val?.village}
                    </MenuItem>
                  ))
                  : villageForReg?.corespondence?.map((val, i) => (
                    <MenuItem value={val?.villageid} key={i}>
                      {val?.village}
                    </MenuItem>
                  ))}
              </Select>
              {errors.cvillageid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cvillageid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {/* <Row>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="caddressline1">
              <InputLabel>
                Address line 1<span className="error">*</span>
              </InputLabel>
              <TextField
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                type="text"
                placeholder="Door no,street"
                {...register("caddressline1", {
                  required: "Address is required",
                  validate: AddressValidationFirstField,
                })}
              />
              {errors.caddressline1 && (
                <small className="text-danger">
                  {errors.caddressline1.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="caddressline2">
              <InputLabel>
                Address line 2<span className="error">*</span>
              </InputLabel>
              <TextField
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                type="text"
                placeholder="Locality"
                {...register("caddressline2", {
                  required: "Address is required",
                  validate: AddressValidation,
                })}
                onInput={AlphabetsandNumbersValidation}
              />
              {errors.caddressline2 && (
                <small className="text-danger">
                  {errors.caddressline2.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="caddressline3">
              <InputLabel>
                Address line 2<span className="error">*</span>
              </InputLabel>
              <TextField
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                type="text"
                placeholder="Locality"
                {...register("caddressline3", {
                  required: "Address is required",
                  validate: AddressValidation,
                })}
                onInput={AlphabetsandNumbersValidation}
              />
              {errors.caddressline3 && (
                <small className="text-danger">
                  {errors.caddressline3.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="cpincode">
              <InputLabel>
                Pincode<span className="error">*</span>
              </InputLabel>
              <TextField
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                type="text"
                placeholder="Enter the pincode"
                {...register("cpincode", {
                  required: "Pincode is required",
                  validate: pincodeValidation,
                })}
                onChange={handlePincodeCorrspondance}
                onInput={NumbersValidation}
              />
              {errors.cpincode && (
                <small className="text-danger">{errors.cpincode.message}</small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="cstateid">
              <InputLabel>
                State<span className="error">*</span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                // value={getValues("pstateid")}
                aria-label="Default select example"
                {...register("cstateid", {
                  required: "state is required",
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("cstateid", stateid);
                  dispatch({
                    type: masterActions.GET_DISTRICT,
                    payload: stateid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.cstateid && (
                <small className="text-danger">{errors.cstateid.message}</small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="cdistrictid">
              <InputLabel>
                District<span className="error">*</span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                aria-label="Default select example"
                value={getValues("cdistrictid")}
                {...register("cdistrictid", {
                  required: "District is required",
                })}
                onChange={(e) => {
                  const districtid = e.target.value;
                  setValue("cdistrictid", districtid);
                  dispatch({
                    type: masterActions.GET_TALUKA,
                    payload: districtid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {district?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.cdistrictid && (
                <small className="text-danger">
                  {errors.cdistrictid.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="ctalukaid">
              <InputLabel>
                Taluka<span className="error">*</span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                aria-label="Default select example"
                // value={getValues("ctalukaid")}
                {...register("ctalukaid", {
                  required: "Taluka is required",
                })}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ctalukaid", talukaid);
                  dispatch({
                    type: masterActions.GET_VILLAGE,
                    payload: talukaid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {taluka?.map((val, i) => (
                  <MenuItem value={val?.talukaid} key={i}>
                    {val?.taluka}
                  </MenuItem>
                ))}
              </Select>
              {errors.ctalukaid && (
                <small className="text-danger">
                  {errors.ctalukaid.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl className="mb-3" controlId="cvillageid">
              <InputLabel>
                Village<span className="error">*</span>
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                aria-label="Default select example"
                // value={getValues("cvillageid")}
                {...register("cvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("cvillageid", villageid);
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {village?.map((val, i) => (
                  <MenuItem value={val?.villageid} key={i}>
                    {val?.village}
                  </MenuItem>
                ))}
              </Select>
              {errors.cvillageid && (
                <small className="text-danger">
                  {errors.cvillageid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Row> */}
        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box sx={{ display: "flex", gap: "8px" }}>
            {/* <SaveButton name={"Edit"} handleDraft={handleEdit} />
            <BackButton name={"clear"} handleBack={handleCancel} />
            <SaveButton
              name={
                disabled
                  ? "Save As Draft"
                  : draftButtonLoader
                  ? "loader"
                  : "Save As Draft"
              }
              handleDraft={() => handleDraft(getValues)}
            />
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            /> */}

            {/* {addressDetails[0]?.status !== 1 &&
              addressDetails[0]?.status !== 2 && (
                <>
                  <BackButton name={"clear"} handleBack={handleCancel} />
                  <SaveButton
                    name={
                      disabled
                        ? "Save As Draft"
                        : draftButtonLoader
                        ? "loader"
                        : "Save As Draft"
                    }
                    handleDraft={() => handleDraft(getValues)}
                  />
                  <SubmitButton
                    name={buttonLoader ? "loader" : "Next"}
                    handleSubmit={handleSubmit(handleSave)}
                  />
                </>
              )} */}

            {isEditMode && (
              <>
                {/* <BackButton name={"clear"} handleBack={handleCancel} /> */}
                <SaveButton
                  name={
                    disabled
                      ? "Save As Draft"
                      : draftButtonLoader
                        ? "loader"
                        : "Save As Draft"
                  }
                  handleDraft={() => handleDraft(getValues)}
                />
                <SubmitButton
                  name={buttonLoader ? "loader" : "Next"}
                  handleSubmit={handleSubmit(handleSave)}
                />
              </>
            )}

            {!isEditMode &&
              (addressDetails[0]?.status === 1 ||
                addressDetails[0]?.status === 2) && (
                <>
                  <SaveButton name={"Edit"} handleDraft={handleEdit} />
                </>
              )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
