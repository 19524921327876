import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoCheckmarkOutline, IoCloseOutline, IoCloudUploadOutline } from "react-icons/io5";
import imageCompression from "browser-image-compression";
import { useSelector, useDispatch } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import masterActions from "../../redux/master/action";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import { PiX } from "react-icons/pi";
import pdf from "../../assets/images/uploads/pdf.png";
import ImageCrop from "./imageCrop";
import ImageCropSignature from "./imageCropSignature";
import BackButton from "../../common/backButton";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { LuCheck, LuPencilLine } from "react-icons/lu";
import { BiSolidUser } from "react-icons/bi";
import signature from "../../../src/assets/images/sidebar/signature 1.png";
import userphoto from "../../../src/assets/images/example.png";
import sign from "../../../src/assets/images/sign.png";

import { GrAttachment, GrTextWrap } from "react-icons/gr";

export default function UploadsForm() {
  const [photofilename, setPhotoImage] = useState("");
  const [photoSize, setPhotoSize] = useState("");
  const [photoSizeCompressed, setPhotoSizeCompressed] = useState("");
  const [signfilename, setSignatureImage] = useState("");
  const [signatureSize, setSignatureSize] = useState("");
  const [signatureSizeCompressed, setSignatureSizeCompressed] = useState("");
  const [size, setSize] = useState("");
  const [photoError, setError] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [documentTypeError, setDocumentTypeError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [photoCheckError, setPhotoCheckError] = useState("");
  const [signatureCheckError, setSignatureCheckError] = useState("");
  const [documentid, setDocumentId] = useState("");
  console.log('documentid--',documentid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fileInputRef = useRef(null);
  const fileInputRefSignature = useRef(null);
  const [open, setOpen] = useState(false);
  const [signatureOpen, setSignatureOpen] = useState(false);

  const handleIconClick = () => {
    setOpen(true);
  };

  const handleNextClick = () => {
    setOpen(false);
    fileInputRef.current.click();
  };

  const handleIconClickSignature = () => {
    setSignatureOpen(true);
  };

  const handleNextSignatureClick = () => {
    setSignatureOpen(false);
    fileInputRefSignature.current.click();
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSignatureOpen(false);
  };


  const [documentName, setDocumentName] = useState("");
  const [documentfilename, setDocument] = useState("");
  const [showModal, setShowModal] = useState({ show: false, data: null });
  const [showModalSignature, setShowModalSignature] = useState({
    show: false,
    data: null,
  });

  const [confirmPhotoImage, setConfirmPhotoImage] = useState(true);
  const [confirmSignatureImage, setConfirmSignatureImage] = useState(true);

  const [cropPhotoImage, setCropPhotoImage] = useState(null);
  const [cropSignatureImage, setCropSignatureImage] = useState(null);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
  const { documentType } = useSelector((state) => state.masterReducer);
  console.log(uploadDetails, "updet");
  const candidateuploaddraftid = uploadDetails[0]?.candidateuploaddraftid
    ? uploadDetails[0]?.candidateuploaddraftid
    : uploadDetails[1]?.candidateuploaddraftid
    ? uploadDetails[1]?.candidateuploaddraftid
    : uploadDetails[2]?.candidateuploaddraftid;
  const candidateuploadid = uploadDetails[0]?.candidateuploadid
    ? uploadDetails[0]?.candidateuploadid
    : uploadDetails[1]?.candidateuploadid
    ? uploadDetails[1]?.candidateuploadid
    : uploadDetails[2]?.candidateuploadid;

  useEffect(() => {
    if (candidateid) {
        
        dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    }
    dispatch({ type: masterActions.GET_DOCUMENT_TYPE });
  }, [candidateid]);

  useEffect(() => {
    if (uploadDetails?.length > 0) {
      setDocumentId(uploadDetails[0]?.documentid || "");
      console.log(uploadDetails);
    }
  }, [uploadDetails]);

  const handleDocumentUpload = (event) => {
    const fileName = event.target.files[0].name;
    const file = event.target.files[0];
    setDocumentName(fileName);
    setDocument(file);
    setDocumentError("");
    event.target.value = null;
  };

  const handleDocumentDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setDocumentName(file.name);
      setDocument(file);
      setDocumentError("");
      event.target.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCloseDocument = () => {
    setDocumentName("");
  };

  const handleDocumentId = (event) => {
    setDocumentId(event.target.value);
    setDocumentTypeError("");
  };

  const handleClose = () => {
    setShowModal({ show: false, data: null });
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);

  const {
    showImage,
    showCompressedImage,
    showSignature,
    showCompressedSignature,
  } = useSelector((state) => state.candidatesReducer);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const fileSizeInKB = file.size / 1024;
    setPhotoSize(fileSizeInKB.toFixed(2));
    setSize(fileSizeInKB);
    if (fileSizeInKB > 300) {
      setShowModal({ show: true, data: null });
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const unCompressedFile = URL.createObjectURL(file);
        const convertedFileCompressed = URL.createObjectURL(compressedFile);
        setCropPhotoImage(convertedFileCompressed);
        dispatch({
          type: candidatesActions.SHOW_IMAGE,
          payload: unCompressedFile,
        });
        setConfirmPhotoImage(false);
        dispatch({
          type: candidatesActions.SHOW_COMPRESSED_IMAGE,
          payload: convertedFileCompressed,
        });
        setSize("");
        setPhotoImage(compressedFile);
        setPhotoSizeCompressed(compressedFile);
      } catch (err) {
        console.log(err);
      }
    } else {
      const convertedFile = URL.createObjectURL(file);
      setCropPhotoImage(convertedFile);
      setConfirmPhotoImage(true);
      setPhotoImage(file);
      dispatch({
        type: candidatesActions.SHOW_IMAGE,
        payload: convertedFile,
      });
      dispatch({
        type: candidatesActions.SHOW_COMPRESSED_IMAGE,
        payload: null,
      });
    }
  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    const fileSizeInKB = file.size / 1024;
    setSize(fileSizeInKB);
    setSignatureSize(fileSizeInKB.toFixed(2));
    if (fileSizeInKB > 300) {
      setShowModalSignature({ show: true, data: null });
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const unCompressedFile = URL.createObjectURL(file);
        const convertedFileCompressed = URL.createObjectURL(compressedFile);
        dispatch({
          type: candidatesActions.SHOW_SIGNATURE,
          payload: unCompressedFile,
        });
        dispatch({
          type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
          payload: convertedFileCompressed,
        });
        setCropSignatureImage(convertedFileCompressed);
        setSignatureSizeCompressed(compressedFile);
        // setConfirmSignatureImage(false);
        setSignatureImage(compressedFile);
      } catch (err) {
        console.log(err);
      }
    } else {
      const convertedFile = URL.createObjectURL(file);
      setCropSignatureImage(convertedFile);
      setConfirmSignatureImage(true);
      setSignatureImage(file);
      dispatch({
        type: candidatesActions.SHOW_SIGNATURE,
        payload: convertedFile,
      });
      dispatch({
        type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
        payload: null,
      });
    }
  };

  function base64ToFile(base64String, fileName) {
    if (!base64String) {
      return null;
    }
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], fileName, { type: mimeString });
  }

  const handleSubmit = () => {
    const operation = candidateuploadid ? 1 : 0;
    // console.log(uploadDetails,'<====upload details')
    const base64DocumentImage = uploadDetails[0]?.document;
    const base64DocumentSignature = uploadDetails[1]?.document;
    const base64DocumentDocument = uploadDetails[2]?.document;

    console.log("base64DocumentImage---", base64DocumentImage);
    console.log("base64DocumentSignature---", base64DocumentSignature);
    console.log("base64DocumentDocument---", base64DocumentDocument);

    const currentSignature = base64ToFile(
      base64DocumentSignature,
      `${candidateid}_signature.jpeg`
    );
    const currentImage = base64ToFile(
      base64DocumentImage,
      `${candidateid}_photo.jpeg`
    );
    const currentDocument = base64ToFile(
      base64DocumentDocument,
      `${uploadDetails[2]?.filename}`
    );
    // if (
    //   photofilename ||
    //   (uploadDetails[0]?.document && documentfilename) ||
    //   (uploadDetails[2]?.document && signfilename) ||
    //   (uploadDetails[1]?.document && documentid)
    // )
    console.log(
      "submit----",
      (photofilename || uploadDetails[0]?.document || cropPhotoImage) &&
        (signfilename || uploadDetails[1]?.document || cropSignatureImage) &&
        (documentfilename || uploadDetails[2]?.document) &&
        (documentid || uploadDetails[0]?.documentid) &&
        (cropPhotoImage ? confirmPhotoImage : uploadDetails[0]?.filename) &&
        (cropSignatureImage
          ? confirmSignatureImage
          : uploadDetails[1]?.filename)
    );
    if (
      (photofilename || uploadDetails[0]?.document || cropPhotoImage) &&
      (signfilename || uploadDetails[1]?.document || cropSignatureImage) &&
      (documentfilename || uploadDetails[2]?.document) &&
      (documentid || uploadDetails[0]?.documentid)
      // &&
      // (cropPhotoImage ? confirmPhotoImage : uploadDetails[0]?.filename) &&
      // (cropSignatureImage ? confirmSignatureImage : uploadDetails[1]?.filename)
    ) {
      const data = {
        photofilename: photofilename ? photofilename : currentImage,
        signfilename: signfilename ? signfilename : currentSignature,
        documentfilename: documentfilename ? documentfilename : currentDocument,
      };
      console.log({
        ...data,
        operation: operation,
        candidateid: candidateid,
        documentid: documentid ? documentid : uploadDetails[0].documentid,
        status: 2,
      });
      dispatch({
        type: candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
        payload: {
          data: {
            ...data,
            operation: operation,
            candidateid: candidateid,
            documentid: documentid ? documentid : uploadDetails[0].documentid,
            status: 2,
          },
          navigate: navigate,
        },
      });
    } else {
      if (!cropPhotoImage && !uploadDetails[0]?.filename) {
        setError("Image is required");
      }
      if (!cropSignatureImage && !uploadDetails[1]?.filename) {
        setSignatureError("Signature is required");
      }
      if (documentid && !documentfilename && !uploadDetails[2]?.filename) {
        setDocumentError("Document is required");
      }
      if (!documentid) {
        setDocumentTypeError("Select one document type");
      }
      // if (!confirmPhotoImage && !uploadDetails[0]?.filename) {
      //   setPhotoCheckError("select the checkbox is required");
      // }
      // if (!confirmSignatureImage) {
      //   setSignatureCheckError("select the checkbox is required");
      // }
    }
    dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
  };

  const handleDraft = () => {

    console.log('handledraft-----');
    console.log('uploadDetails[1]?.document---',uploadDetails[1]?.document);
    const operation = candidateuploaddraftid ? 1 : 0;
    const base64DocumentImage = uploadDetails[0]?.document ? uploadDetails[0]?.document : null;
    const base64DocumentSignature = uploadDetails[1]?.document ? uploadDetails[1]?.document : null;
    const base64DocumentDocument = uploadDetails[2]?.document ? uploadDetails[2]?.document : null; 
    const currentSignature = base64DocumentSignature ? base64ToFile(
      base64DocumentSignature,
      `${candidateid}_signature.jpeg`
    ) : null;
    const currentImage =base64DocumentImage ? base64ToFile(
      base64DocumentImage,
      `${candidateid}_photo.jpeg`
    ): null;
    const currentDocument =base64DocumentDocument ?  base64ToFile(
      base64DocumentDocument,
      `${uploadDetails[2]?.filename}`
    ) : null;
    const data = {
      photofilename: photofilename ? photofilename : currentImage ? currentImage : null,
      signfilename: signfilename ? signfilename : currentSignature ? currentSignature : null,
      documentfilename: documentfilename ? documentfilename : currentDocument ?currentDocument : null,
    };
    console.log('signfilename---',signfilename);
    console.log('currentSignature---',currentSignature);

    console.log('draft data---',data);
    dispatch({
      type: candidatesActions.PROFILE_UPLOADS_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...data,
          operation: parseInt(operation),
          candidateid: parseInt(candidateid),
          documentid: documentid ? documentid : uploadDetails[0]?.documentid ? uploadDetails[0]?.documentid : 0,
          status: 1,
        },
      },
    });

    setTimeout(() => {
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    }, 1000);
  };

  const onCropDone = (imgCroppedArea) => {
    if (cropPhotoImage) {
      const canvasEle = document.createElement("canvas");
      canvasEle.width = imgCroppedArea.width;
      canvasEle.height = imgCroppedArea.height;
      const context = canvasEle.getContext("2d");
      let croppedImage = new Image();
      croppedImage.src = cropPhotoImage;
      croppedImage.onload = function () {
        context.drawImage(
          croppedImage,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
        );
        const dataURL = canvasEle.toDataURL("image/jpeg");
        if (size <= 100) {
          dispatch({
            type: candidatesActions.SHOW_IMAGE,
            payload: dataURL,
          });
          dispatch({
            type: candidatesActions.SHOW_COMPRESSED_IMAGE,
            payload: dataURL,
          });
        } else {
          dispatch({
            type: candidatesActions.SHOW_COMPRESSED_IMAGE,
            payload: dataURL,
          });
          dispatch({
            type: candidatesActions.SHOW_IMAGE,
            payload: dataURL,
          });
        }
        // setSize("");
        setShowModal({ show: false, data: null });

        const byteString = atob(dataURL.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        const file = new File([blob], "croppedImage.jpg", {
          type: "image/jpeg",
        });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.files = dataTransfer.files;
        setPhotoImage(fileInput.files[0]);
      };
    }
  };

  const onCropDoneSignature = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;
    const context = canvasEle.getContext("2d");
    let croppedImage = new Image();
    croppedImage.src = cropSignatureImage;
    croppedImage.onload = function () {
      context.drawImage(
        croppedImage,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      const dataURL = canvasEle.toDataURL("image/jpeg");
      if (size <= 100) {
        dispatch({
          type: candidatesActions.SHOW_SIGNATURE,
          payload: dataURL,
        });
        dispatch({
          type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
          payload: null,
        });
      } else {
        dispatch({
          type: candidatesActions.SHOW_SIGNATURE,
          payload: dataURL,
        });
        dispatch({
          type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
          payload: dataURL,
        });
      }
      setShowModalSignature({ show: false, data: null });
      const byteString = atob(dataURL.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "image/jpeg" });
      const file = new File([blob], `croppedImage.jpg`, { type: "image/jpeg" });
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.files = dataTransfer.files;
      setSignatureImage(fileInput.files[0]);
    };
  };

  const onCropCancelSignature = () => {
    setShowModalSignature({ show: false, data: null });
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
      payload: null,
    });

  };

  const onCropCancel = () => {
    setShowModal({ show: false, data: null });
    dispatch({ type:candidatesActions.SHOW_IMAGE,
    payload:null})
    dispatch({ type:candidatesActions.SHOW_COMPRESSED_IMAGE,
    payload:null})
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
    );
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: null,
    });

    setPhotoImage("");
    setPhotoSize("");
    setSignatureImage("");
    setSignatureSize("");
  };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        border: " 1px solid #0F4F96",
      }}
    >
      <Grid container>
        <Grid item lg={7}>
          {uploadDetails[0]?.filename !== "undefined" &&
          uploadDetails[0]?.filename &&
          !showImage &&
          !showCompressedImage ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Image
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              {/* {uploadDetails[0]?.filename} */}
              <Box
                sx={{
                  display: "flex",
                  gap: "2%",
                  marginTop: ".4rem",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {/* {showImage && ( */}
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={uploadDetails[0]?.document}
                      alt="Original Image"
                      style={{ maxWidth: "80px", borderRadius: "4px" }}
                    />

                    <IconButton
                      onClick={handleIconClick} // Trigger the file input when the icon is clicked
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        backgroundColor: "#0F4F96",
                        borderRadius: "50%",
                        padding: "4px",
                        color: "#ffffff",
                        border: "2px solid #FFFFFF",
                      }}
                    >
                      <LuPencilLine style={{ width: "16px", height: "16px" }} />
                    </IconButton>

                    <input
                      type="file"
                      ref={fileInputRef} // Reference to file input
                      name="photofilename"
                      id="photofilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }} // Hide the input
                      onChange={handleImageUpload} // Handle file upload
                    />
                  </Box>

                  <Typography variant="caption" style={{ paddingTop: "10px" }}>
                    Uploaded image
                  </Typography>

                  {/* 
      {cropPhotoImage !== "" && (
        <small className="photo-size">
          {(photoSize.size / 1024).toFixed(2)} KB
        </small>
      )} 
      */}
                </Box>
                {/* )} */}
                {/* {showImage && (
                   <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showImage}
                      alt="Original Image"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography style={{ paddingTop: "10px" }}>Uploaded image</Typography>
                    {cropPhotoImage !== "" && (
                      <small className="photo-size">
                        {(photoSize.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}
                {showCompressedImage && (
                  <>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={showCompressedImage}
                        alt="Compressed Image"
                        style={{ maxWidth: "80px" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography style={{ paddingTop: "10px" }}>Compressed image</Typography>
                        <Box className="form-check">
                          <input
                            className="form-check-input rounded-circle is-valid"
                            type="checkbox"
                            checked={confirmPhotoImage}
                            onChange={(e) => {
                              setConfirmPhotoImage(e.target.checked);
                              if (e.target.checked) {
                                setPhotoCheckError("");
                              }
                            }}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </Box>
                      </Box>
                      {cropPhotoImage !== "" && (
                        <small className="photo-size">
                          {(photoSize.size / 1024).toFixed(2)} KB
                        </small>
                      )}
                    </Box>
                  </>
                )} */}
                {/* <Box
                  style={{
                    border: "1px solid #203272",
                    width: "180px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    alignSelf: isMobile ? "flex-start" : "end",
                  }}
                >
                  <input
                    type="file"
                    name="photofilename"
                    id="photofilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="photofilename"
                    style={{
                      cursor: "pointer",
                      color: "#203272",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload new
                    image
                  </label>
                </Box> */}
              </Box>
              {photoError && <small className="error">{photoError}</small>}
              <br />
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </Box>
          ) : showImage ? (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Image
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Box style={{ display: "flex", gap: "5%" }}>
                {showImage && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={showImage}
                        alt="Original Image"
                        style={{ maxWidth: "80px", borderRadius: "4px" }}
                      />
                      <IconButton
                        onClick={handleIconClick} // Trigger the file input when the icon is clicked
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                    </Box>

                    <input
                      type="file"
                      ref={fileInputRef} // Reference to file input
                      name="photofilename"
                      id="photofilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }} // Hide the input
                      onChange={handleImageUpload} // Handle file upload
                    />

                    <Typography
                      variant="caption"
                      style={{ paddingTop: "10px" }}
                    >
                      Uploaded image
                    </Typography>

                    {cropPhotoImage !== "" && (
                      <small className="photo-size">{photoSize} KB</small>
                    )}
                  </Box>
                )}
                {showCompressedImage && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedImage}
                      alt="Compressed Image"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Compressed image
                      </Typography>
                      {/* <Box className="form-check">
                        <Checkbox
                          checked={confirmPhotoImage}
                          onChange={(e) => {
                            setConfirmPhotoImage(e.target.checked);
                            if (e.target.checked) {
                              setPhotoCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box> */}
                    </Box>
                    {cropPhotoImage !== "" && (
                      <small className="photo-size">
                        {(photoSizeCompressed.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}

                {/* <Box
                  style={{
                    border: "1px solid #203272",
                    width: "180px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    alignSelf: "end",
                  }}
                >
                  <input
                    type="file"
                    name="photofilename"
                    id="photofilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="photofilename"
                    style={{
                      cursor: "pointer",
                      color: "#203272",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload new
                    image
                  </label>
                </Box> */}
              </Box>
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </>
          ) : (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Image
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Box
                style={{
                  backgroundColor: "#CFDCEA",
                  width: "120px",
                  height: "140px",
                  borderRadius: "8px",
                  padding: "3px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <IconButton
                  onClick={handleIconClick} // Handle click to trigger file input
                  style={{
                    position: "absolute",
                    top: "-3px",
                    right: "-5px",
                    backgroundColor: "#0F4F96",
                    borderRadius: "50%",
                    padding: "1px",
                    border: "2.08px solid #FFFFFF",
                  }}
                >
                  <LuPencilLine
                    style={{ color: "#ffffff", width: "19px", height: "19px" }}
                  />
                </IconButton>

                <input
                  type="file"
                  ref={fileInputRef} // Reference for the file input
                  name="photofilename"
                  id="photofilename"
                  accept=".jpg, .jpeg, .png"
                  style={{
                    display: "none", // Hide the file input element
                  }}
                  onChange={handleImageUpload}
                />

                <BiSolidUser
                  style={{ width: "78px", height: "78px", color: "#0F4F96" }}
                />
              </Box>
              <Typography
                variant="body1"
                mt={1}
                sx={{
                  color: "#0000008A",
                  lineHeight: "22px",
                  // paddingleft: "2px",
                  // marginLeft: "40px",
                }}
              >
                (JPEG,JPG or png)
                <br />
                (max size - 300 kB)
              </Typography>

              {photoError && <small className="error">{photoError}</small>}
              <br />
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </>
          )}

          {uploadDetails[1]?.filename !== "undefined" &&
          uploadDetails[1]?.filename &&
          !showSignature &&
          !showCompressedSignature ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Signature
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              {/* {uploadDetails[1]?.filename} */}
              <Box
                style={{
                  display: "flex",
                  gap: "2%",
                  marginTop: ".4rem",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {/* {showSignature && ( */}
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={uploadDetails[1]?.document !== "undefined" && uploadDetails[1]?.document } // Second element for signature
                      alt="Original Signature"
                      style={{ maxWidth: "80px", borderRadius: "4px" }}
                    />

                    <IconButton
                      onClick={handleIconClickSignature} // Trigger the file input when the icon is clicked
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        backgroundColor: "#0F4F96",
                        borderRadius: "50%",
                        padding: "4px",
                        color: "#ffffff",
                        border: "2px solid #FFFFFF",
                      }}
                    >
                      <LuPencilLine style={{ width: "16px", height: "16px" }} />
                    </IconButton>

                    <input
                      type="file"
                      ref={fileInputRefSignature} // Reference to file input for signature
                      name="signaturefilename"
                      id="signaturefilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }} // Hide the input
                      onChange={handleSignatureUpload} // Handle signature file upload
                    />
                  </Box>

                  <Typography variant="caption" style={{ paddingTop: "10px" }}>
                    Uploaded signature
                  </Typography>

                  {/* 
        {cropSignatureImage !== "" && (
          <small className="photo-size">
            {(signatureSize.size / 1024).toFixed(2)} KB
          </small>
        )} 
        */}
                </Box>
                {/* )} */}
                {/* {showSignature && (
                 <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showSignature}
                      alt="Original Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography style={{ paddingTop: "10px" }}>Uploaded signature</Typography>
                    </Box>)}
                {showCompressedSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedSignature}
                      alt="Compressed Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography style={{ paddingTop: "10px" }}>
                        Compressed signature
                      </Typography>
                      <Box className="form-check">
                        <input
                          className="form-check-input rounded-circle is-valid"
                          type="checkbox"
                          checked={confirmSignatureImage}
                          onChange={(e) => {
                            // setConfirmSignatureImage(e.target.checked);
                            if (e.target.checked) {
                              setSignatureCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box>
                    </Box>
                    {cropSignatureImage !== "" && (
                      <small className="photo-size">
                        {(signatureSize.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )} */}
                {/* <Box
                  style={{
                    border: "1px solid #203272",
                    minWidth: "205px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    display: "flex",
                    alignSelf: isMobile ? "flex-start" : "end",
                  }}
                >
                  <input
                    type="file"
                    name="signfilename"
                    id="signfilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleSignatureUpload}
                  />
                  <label
                    htmlFor="signfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color: "#203272",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload new
                    signature
                  </label>
                </Box> */}
              </Box>
              {signatureError && (
                <small className="error">{signatureError}</small>
              )}
              <br />
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </Box>
          ) : showSignature ? (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Signature
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Box style={{ display: "flex", gap: "5%" }}>
                {showSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={showSignature}
                        alt="Original Signature"
                        style={{ maxWidth: "80px", borderRadius: "4px" }}
                      />
                      <IconButton
                        onClick={handleIconClickSignature}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                    </Box>

                    <input
                      type="file"
                      ref={fileInputRefSignature} // Reference to file input
                      name="signaturefilename"
                      id="signaturefilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }} // Hide the input
                      onChange={handleSignatureUpload} // Handle file upload
                    />

                    <Typography
                      variant="caption"
                      style={{ paddingTop: "10px" }}
                    >
                      Uploaded signature
                    </Typography>

                    {cropSignatureImage !== "" && (
                      <small className="photo-size">{signatureSize} KB</small>
                    )}
                  </Box>
                )}
                {showCompressedSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedSignature}
                      alt="Compressed Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Compressed signature
                      </Typography>
                      {/* <Box className="form-check">
                        <Checkbox
                          checked={confirmSignatureImage}
                          onChange={(e) => {
                            // setConfirmSignatureImage(e.target.checked);
                            if (e.target.checked) {
                              setSignatureCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box> */}
                    </Box>
                    {cropSignatureImage !== "" && (
                      <small className="photo-size">
                        {(signatureSizeCompressed.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}
                {/* <Box
                  style={{
                    border: "1px solid #203272",
                    minWidth: "205px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    display: "flex",
                    alignSelf: "end",
                  }}
                >
                  <input
                    type="file"
                    name="signfilename"
                    id="signfilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleSignatureUpload}
                  />
                  <label
                    htmlFor="signfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color: "#203272",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload new
                    signature
                  </label>
                </Box> */}
              </Box>
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </>
          ) : (
            <>
              <br />
              <InputLabel
                htmlFor="signfilename"
                className="mt-3"
                style={{ fontWeight: "bold" }}
              >
                Upload Signature
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Box
                style={{
                  backgroundColor: "#CFDCEA",
                  width: "240px",
                  height: "119px",
                  borderRadius: "8px",
                  padding: "3px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <IconButton
                  onClick={handleIconClickSignature}
                  style={{
                    position: "absolute",
                    top: "-3px",
                    right: "-5px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    padding: "1px",
                    backgroundColor: "#0F4F96",
                    border: " 2.08px solid #FFFFFF",
                  }}
                >
                  <LuPencilLine
                    style={{ color: "#ffffff", width: "19px", height: "19px" }}
                  />
                </IconButton>
                <img
                  src={signature}
                  style={{
                    width: "179px",
                    height: "62px",
                  }}
                />
                <input
                  type="file"
                  ref={fileInputRefSignature}
                  name="signfilename"
                  id="signfilename"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleSignatureUpload}
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                />
                {/* <label
                  htmlFor="signfilename"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  &nbsp;
                  <IoCloudUploadOutline size={22} /> &nbsp; Drag or drop your
                  image, or <span style={{ color: "blue" }}> &nbsp;browse</span>
                </label> */}
              </Box>
              <Typography
                variant="body1"
                mt={1}
                sx={{
                  color: "#0000008A",
                  lineHeight: "22px",
                  paddingleft: "2px",
                  marginLeft: "40px",
                }}
              >
                (JPEG,JPG or png)
                <br />
                (max size - 300 kB)
              </Typography>
              {signatureError && (
                <small className="error">{signatureError}</small>
              )}
              <br />
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </>
          )}
          <br />
          <InputLabel htmlFor="document" style={{ fontWeight: "bold" }}>
            Photo Identity
            <span className="error" style={{ color: "#666666" }}>
              *
            </span>
          </InputLabel>
          <Grid
            container
            mt={1}
            spacing={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item md={5}>
              <FormControl fullWidth>
                <InputLabel id="select-doc-label">
                  Select Document Type*
                </InputLabel>
                <Select
                  label="Select Document Type*"
                  value={documentid}
                  id="documentid"
                  onChange={handleDocumentId}
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <MenuItem value="">Select One</MenuItem>
                  {documentType?.map((val, i) => (
                    <MenuItem
                      sx={{ textWrap: "wrap" }}
                      value={val?.documenttypeid}
                      key={i}
                    >
                      <span style={{ textWrap: "wrap" }}>
                        {val?.documenttype}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item md={7}>
              {documentid && !documentName && !uploadDetails[2]?.filename && (
                <Box
                  style={{
                    border: "1px solid #0000003D",
                    borderRadius: "8px",
                    maxWidth: "323px",
                    minHeight: "54px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onDrop={handleDocumentDrop}
                  onDragOver={handleDragOver}
                >
                  <input
                    type="file"
                    name="documentfilename"
                    id="documentfilename"
                    accept=".pdf"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleDocumentUpload}
                  />
                  <label
                    htmlFor="documentfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      padding: "18px",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "0000003D",
                    }}
                  >
                    Choose File To upload
                    <GrAttachment size={22} />
                  </label>
                </Box>
              )}
            </Grid> */}
            {documentName && (
              <Box
                style={{
                  width: "17.4em",
                  height: "fit-content",
                  border: "1px solid #D5D5D5",
                  display: "flex",
                  alignItems: "center",
                  padding: "4px",
                  marginTop: "1rem",
                  margin: "6px 0px 0px 8px",
                  borderRadius: "8px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    flexGrow: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src={pdf}
                    style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                    alt="pdf"
                  />
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "calc(100% - 50px)",
                    }}
                  >
                    {documentName}
                  </span>
                </Box>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseDocument}
                >
                  <PiX />
                </div>
              </Box>
            )}
            {documentTypeError && (
              <small
                className="error"
                style={{ padding: "0px 8px", marginTop: ".2rem" }}
              >
                {documentTypeError}
              </small>
            )}
          </Grid>

          {uploadDetails[2]?.filename !== "undefined" &&
            uploadDetails[2]?.filename &&
            !documentName && (
              <>
                <Box
                  style={{
                    width: isMobile ? "10rem" : "17.5rem",
                    height: "60px",
                    border: "1px solid #D5D5D5",
                    display: "flex",
                    alignItems: "center",
                    gap: "1%",
                    marginTop: "1rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={pdf}
                    style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                    alt="pdf"
                  />
                  <span
                    style={{
                      // textOverflow: "ellipsis",
                      textWrap: "wrap",
                      overflow: "hidden",
                    }}
                  >
                    {uploadDetails[2]?.filename}
                  </span>
                </Box>
               
              </>
            )}

             <Box
                  style={{
                    border: "1px solid #0000003D",
                    borderRadius: "8px",
                    maxWidth: "280px",
                    minHeight: "52px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "16px",
                  }}
                >
                  <input
                    type="file"
                    name="documentfilename"
                    id="documentfilename"
                    accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleDocumentUpload}
                  />
                  <label
                    htmlFor="documentfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      padding: "18px",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "0000003D",
                    }}
                  >
                     {(uploadDetails &&  uploadDetails[2]?.filename) ?  "Upload New Document" : "Upload Document" }
                    <GrAttachment size={22} />
                  </label>
                </Box>
          <small
            className="error"
            style={{ color: "#666666", textWrap: "wrap" }}
          >
            {" "}
            Only JPEG, PNG, PDF, and DOC/DOCX files are allowed
          </small>
          <br />
          {documentError && <small className="error">{documentError}</small>}
        </Grid>
        <Grid item lg={5}>
          <Box
            sx={{
              minHeight: "5rem",
              maxWidth: "541px",
              padding: "1rem",
              backgroundColor: "#F3F6FA",
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid #0F4F96",
              borderRadius: "20px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for Photo:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Your photo must be taken within the last six months to
                  reflect your current appearance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Your head should be centered and facing forward, covering
                  about 50-70% of the photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Both of your ears should be visible, and your eyes should
                  be open and clearly visible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. Use a plain white or off-white background, free from
                  shadows or other decorations in your photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  5. Ensure your photo is well-lit with no shadows on your face
                  or in the background.
                </Typography>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{ color: "#504E50", fontWeight: 500, marginTop: "1rem" }}
            >
              Instructions for Signature:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Make sure your signature is clear and legible without any
                  smudges or overlaps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Use a plain white background for your signature to ensure
                  clear visibility.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Use a black or dark blue ink pen to sign. Avoid using other
                  colors as they may not scan well.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. The signature should be done by you personally, not by any
                  other person or digitally generated.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", position: "relative" }}
          >
            SAMPLE IMAGE
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={userphoto}
                alt="Sample"
                style={{
                  width: "135px",
                  height: "148px",
                  borderRadius: "8px",
                }}
              />
            </Box>

            {/* Instructions Section */}
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for Photo:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Your photo must be taken within the last six months to
                  reflect your current appearance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Your head should be centered and facing forward, covering
                  about 50-70% of the photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Both of your ears should be visible, and your eyes should
                  be open and clearly visible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. Use a plain white or off-white background, free from
                  shadows or other decorations in your photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  5. Ensure your photo is well-lit with no shadows on your face
                  or in the background.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextClick}
              name={buttonLoader ? "loader" : "Next"}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={signatureOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center" }}
          >
            SAMPLE SIGNATURE{" "}
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={sign}
                alt="Sample"
                style={{
                  width: "181px",
                  height: "88px",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              />
              <IoCheckmarkOutline
                style={{
                  width: "23px",
                  height: "23px",
                  right: 14,
                  position: "relative",
                  top: -31,
                  backgroundColor: "#03A600",
                  borderRadius: "50%",
                  color: "#FFFFFF",
                }}
              />
            </Box>

            {/* Instructions Section */}
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for signature:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Make sure your signature is clear and legible without any
                  smudges or overlaps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Use a plain white background for your signature to ensure
                  clear visibility.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Use a black or dark blue ink pen to sign. Avoid using other
                  colors as they may not scan well.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4.The signature should be done by you personally, not by any
                  other person or digitally generated.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextSignatureClick}
              name={buttonLoader ? "loader" : "Next"}
            />
          </DialogActions>
        </Dialog>

        <Box sx={{ display: "flex", gap: "8px" }}>
          <BackButton handleBack={handleBack} />
        </Box>

        <Box sx={{ display: "flex", gap: "8px" }}>
          {/* <BackButton name={"clear"} /> */}

          <SaveButton name={"Save As Draft"} handleDraft={handleDraft} />
          <SubmitButton
            handleSubmit={handleSubmit}
            name={buttonLoader ? "loader" : "Submit"}
          />
        </Box>
      </Grid>
      {cropPhotoImage && (
        <ImageCrop
          showModal={showModal?.show}
          handleClose={handleClose}
          image={cropPhotoImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {cropSignatureImage && (
        <ImageCropSignature
          showModal={showModalSignature?.show}
          handleClose={handleClose}
          image={cropSignatureImage}
          onCropDone={onCropDoneSignature}
          onCropCancel={onCropCancelSignature}
        />
      )}
    </Grid>
  );
}
